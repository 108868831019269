import { Box, Grid } from "@mui/material";
import { ImageIcon } from "components/shared/ImageIcon";
import React from "react";
import "./Tags.scss";
export function Tags(props: any) {
  return (
    <Grid className={`tag-container ${props.tagClass ? props.tagClass : ""}`}>
      <label className={props.className ? props.className : ""}>
        {props.label ? props.label : ""} {props.isColon ? props.isColon : ""}
        <span className="value-field">{props.value ? props.value : ""}</span>
        <span>
          {props.iconClass ? (
            <Box className="country-icon">
              <ImageIcon classes={props.iconClass}></ImageIcon>
            </Box>
          ) : null}
        </span>
      </label>
      {props.children}
    </Grid>
  );
}
