import React, { FC, useEffect, useRef } from "react";
import { useState } from "react";
import { DateRange, Range } from "react-date-range";
import { Box } from "@mui/material";

type DateRangeTargetProps = {
  handleUpdateDate: (value: Range) => void;
  setIsExpandDateRange: React.Dispatch<React.SetStateAction<boolean>>;
  startDate?: Date | string;
  endDate?: Date | string;
};
const DateRangeTarget: FC<DateRangeTargetProps> = ({
  handleUpdateDate,
  setIsExpandDateRange,
  startDate,
  endDate,
}) => {
  const [state, setState] = useState<Array<Range>>([
    {
      startDate:
        startDate && startDate instanceof Date ? startDate : new Date(),
      endDate: endDate && endDate instanceof Date ? endDate : new Date(),
      key: "selection",
    },
  ]);

  const refDate = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (refDate.current && !refDate.current.contains(e.target)) {
        setIsExpandDateRange(false);
        handleUpdateDate(state[0]);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandDateRange, handleUpdateDate, state]);

  return (
    <Box
      style={{ position: "absolute", zIndex: 1, right: 0, top: 90 }}
      ref={refDate}
    >
      <DateRange
        editableDateInputs={true}
        onChange={(item:any) => {
          setState([item.selection]);
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />
    </Box>
  );
};

export default DateRangeTarget;
