import { apiEndPoints } from "core/apiEndpoints";
import { httpRequest } from "core/httpRequest";
import { RECORDS_PER_PAGE } from "core/constants";
import { UpdateItem } from "models/dashboard";
import { AxiosResponse } from "axios";

/**
 * Get dashboard info
 * @returns promise
 */
export const getDashboardData = (
  filterValues?: string | null,
  pageNumber: number = 1,
  countryCode?: string[] | null,
  channels?: string | null
) => {
  let url =
    apiEndPoints.dashboard.url +
    "?pageNo=" +
    pageNumber +
    "&size=" +
    RECORDS_PER_PAGE;
  if (filterValues) {
    url = url + "&dataSource=" + filterValues;
  }
  if (countryCode) {
    url = url + "&country=" + countryCode;
  }
  if (channels) {
    url = url + "&channel=" + channels;
  }
  let endpoint = {
    ...apiEndPoints.dashboard,
    url: url,
  };
  return httpRequest(endpoint, "get", null, "app");
};
/**
 * Get dashboard item details
 * @returns promise
 */
export const getDashboardItemDetails = async (
  id: any
): Promise<AxiosResponse<any> | undefined> => {
  let endpoint = {
    ...apiEndPoints.dashboardDetail,
    url: apiEndPoints.dashboardDetail.url + id,
  };
  return httpRequest(endpoint, "get", null, "app");
};
/**
 * delete dashboard item
 * @param id
 * @returns
 */
export const deleteDashboardItem = (id: number) => {
  let endpoint = {
    ...apiEndPoints.deleteDashboradItem,
    url: apiEndPoints.deleteDashboradItem.url + id,
  };
  return httpRequest(endpoint, "post", id, "app");
};
/**
 * Update saved comment or drivers
 * @returns promise
 */

export const updateTargetsData = (updateSavedParameters: UpdateItem | null) => {
  return httpRequest(
    apiEndPoints.updateSavedTargets,
    "put",
    updateSavedParameters,
    "app"
  );
};
