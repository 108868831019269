import { Box, Grid } from "@mui/material";
import {
  FilterItem,
  OperatorType,
  SubGroupFilterItem,
} from "pages/TargetV2/types/targetV2.types";
import React, { FC, useCallback, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import SimpleDateRange from "../../filterRow/dateRange/SimpleDateRange";
import "../SubGroupFilterCustom.scss";
import SelectOperatorDropDown from "../../components/selectOperatorDropDown/SelectOperatorDropDown";
import { useTranslation } from "react-i18next";
import OperatorLine from "../../components/operatorLine/OperatorLine";
import SurveyFilterRow from "../../filterRow/survey/SurveyFilterRow";

type SurveyContainerProps = {
  subGroupFilterItem: SubGroupFilterItem;
  index: number;
};

const SurveyContainer: FC<SurveyContainerProps> = ({
  subGroupFilterItem,
  index,
}) => {
  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const [isExpandFilterOfOperator, setIsExpandFilterOfOperator] =
    useState<boolean>(false);
  const [expandedOperatorForAnswers, setExpandedOperatorForAnswers] = useState<
    number | null
  >(null);

  const {
    handleDeleteSubGroupFilterItem,
    isApplyingFilters,
    handleUpdateOperator,
    arrayOfOperators,
    handleUpdateOperatorBetweenFiltersSelected,
  } = useTargetV2Context();

  const { t } = useTranslation();

  const handleUpdateOperatorClick = useCallback(
    (operator: string) => {
      const operatorToAdd: OperatorType = arrayOfOperators.filter(
        (e) => e === operator
      )[0];
      handleUpdateOperator(subGroupFilterItem.subGroupId, operatorToAdd);
      setIsExpandFilterOfOperator(false);
    },
    [handleUpdateOperator, subGroupFilterItem, arrayOfOperators]
  );

  useEffect(() => {
    subGroupFilterItem.filterItemsChildren[0].startDateSelected &&
      subGroupFilterItem.filterItemsChildren[1].filtersSelected.length &&
      setShowQuestions(true);
  }, [subGroupFilterItem]);

  const multiQuestionStyled = useCallback((filterItem: FilterItem) => {
    if (filterItem.multiSelect) {
      return {
        paddingBottom: "32px",
        border: "1px solid #e6e6e6",
        borderRadius: "8px",
        padding: "12px",
        gap: "34px",
      };
    }
  }, []);

  const handleUpdateOperatorForAnswersClick = useCallback(
    (operator: string, i?: number) => {
      const operatorToAdd: OperatorType = arrayOfOperators.filter(
        (e) => e === operator
      )[0];
      if (i !== undefined) {
        handleUpdateOperatorBetweenFiltersSelected(
          subGroupFilterItem.subgGroupFilterItemsChildren[0].subGroupId,
          i,
          operatorToAdd
        );
        setExpandedOperatorForAnswers(null);
      }
    },
    [
      handleUpdateOperatorBetweenFiltersSelected,
      subGroupFilterItem,
      arrayOfOperators,
    ]
  );

  return (
    <Grid
      className="sub-group-custom-wrapper"
      minHeight="360px"
      position="relative"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop="8px"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap="22px">
          <Box className="past-interaction-title-wrapper">
            <Box className="past-interaction-title">
              {subGroupFilterItem.name}
            </Box>
          </Box>
          <Box className="operator-select">{t("select_your_operator")}</Box>

          <SelectOperatorDropDown
            operator={subGroupFilterItem.operator}
            values={arrayOfOperators}
            index={index}
            handleUpdateOperatorClick={handleUpdateOperatorClick}
            expandedFilterOfOperator={isExpandFilterOfOperator}
            setExpandedFilterOfOperator={setIsExpandFilterOfOperator}
          />
        </Box>

        <CancelIcon
          onClick={() =>
            handleDeleteSubGroupFilterItem(subGroupFilterItem.parentId, index)
          }
          cursor="pointer"
          className="cancel-icon"
        />
      </Box>

      <Box>
        {subGroupFilterItem.filterItemsChildren.length > 0 && (
          <SimpleDateRange
            key={subGroupFilterItem.filterItemsChildren[0].filterId}
            filterItem={subGroupFilterItem.filterItemsChildren[0]}
            id={subGroupFilterItem.subGroupId}
            index={index}
          />
        )}

        {subGroupFilterItem.filterItemsChildren.length > 0 &&
          subGroupFilterItem.filterItemsChildren[0].startDateSelected && (
            <Box padding="8px">
              <SurveyFilterRow
                key={subGroupFilterItem.filterItemsChildren[1].filterId}
                filterItem={subGroupFilterItem.filterItemsChildren[1]}
                id={subGroupFilterItem.subGroupId}
                index={1}
                width="50%"
              />
            </Box>
          )}

        <Box marginBottom="22px" />

        {subGroupFilterItem.subgGroupFilterItemsChildren?.length > 0 &&
          showQuestions &&
          subGroupFilterItem.subgGroupFilterItemsChildren.map((subGroup) => (
            <Box
              key={subGroup.subGroupId}
              className="first-subgroup-container"
              paddingBottom="44px"
              paddingTop="44px"
            >
              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
                justifyContent="space-between"
                maxWidth="95%"
              >
                {subGroup.filterItemsChildren.map((filterItem, index) => (
                  <Box key={filterItem.id}>
                    <Box
                      position="relative"
                      style={{
                        ...multiQuestionStyled(filterItem),
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {filterItem.multiSelect && (
                        <Box display="flex" alignItems="center" gap="22px">
                          <Box className="operator-select">
                            {t("select_your_operator_answers")}
                          </Box>

                          <Box width="120px">
                            <SelectOperatorDropDown
                              values={arrayOfOperators}
                              index={index}
                              handleUpdateOperatorClick={
                                handleUpdateOperatorForAnswersClick
                              }
                              expandedFilterOfOperator={
                                expandedOperatorForAnswers === filterItem.id
                              }
                              setExpandedFilterOfOperator={() => {
                                if (
                                  expandedOperatorForAnswers === filterItem.id
                                ) {
                                  setExpandedOperatorForAnswers(null);
                                } else {
                                  setExpandedOperatorForAnswers(filterItem.id);
                                }
                              }}
                              operator={
                                filterItem.operatorBetweenFiltersSelected
                              }
                            />
                          </Box>
                        </Box>
                      )}
                      <SurveyFilterRow
                        key={filterItem.filterId}
                        filterItem={filterItem}
                        id={subGroup.subGroupId}
                        index={index}
                        width="50%"
                        style={{
                          justifyContent: "space-between",
                          marginLeft: "12px",
                        }}
                        isQuestion={true}
                      />
                    </Box>
                    {index < subGroup.filterItemsChildren.length - 1 && (
                      <Box marginTop="24px" marginBottom="24px">
                        <OperatorLine
                          operator={subGroupFilterItem.operator}
                          width="100%"
                        />
                      </Box>
                    )}
                    {isApplyingFilters && filterItem.countHCPS !== null && (
                      <Box
                        style={{
                          position: "absolute",
                          right: -134,
                          top: 44,
                        }}
                      >
                        <Box className="count-hcps-wrapper">
                          <Box className="count-hcps-number">
                            {filterItem.countHCPS} HCPS
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Grid>
  );
};

export default SurveyContainer;
