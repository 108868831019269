import { apiEndPoints } from "core/apiEndpoints";
import { httpRequest } from "core/httpRequest";
import { updateSavedTargetsItem } from "models/savedTargets";

/**
 * Get saved Targets Response info
 * @returns promise
 */
export const getTargetDetails = (key: any) => {
  let endpoint = {
    ...apiEndPoints.getTargetDetails,
    url: apiEndPoints.getTargetDetails.url + key,
  };
  return httpRequest(endpoint, "get", null, "app");
};
/**
 * refresh saved target list item
 */
export const getRefreshTarget = (id: number) => {
  let endpoint = {
    ...apiEndPoints.refreshTarget,
    url: apiEndPoints.refreshTarget.url + "/" + id,
  };
  return httpRequest(endpoint, "get", id, "app");
};

/**
 * Update saved target lit item
 * @returns promise
 */

export const getUpdateSavedTargetsData = (
  updateSavedTargetsParameters: updateSavedTargetsItem | null
) => {
  return httpRequest(
    apiEndPoints.targetV2SaveTarget,
    "post",
    updateSavedTargetsParameters,
    "app"
  );
};
