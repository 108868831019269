import { apiEndPoints } from "core/apiEndpoints";
import { httpRequest } from "core/httpRequest";
import { RECORDS_PER_PAGE } from "core/constants";

/**
 * Get suggestion component info
 * @returns promise
 */

export const getSuggestionData = (
  actionFilter?: any | null,
  pageNumber: number = 1,
  countryCode?: string[] | null,
  priorityFilter?: any | null,
  size: number = RECORDS_PER_PAGE
) => {
  let url =
    apiEndPoints.suggestionsHomePage.url +
    "?pageNo=" +
    pageNumber +
    "&size=" +
    size;
  if (actionFilter) {
    url = url + "&status=" + actionFilter;
  }
  if (countryCode) {
    url = url + "&countryCode=" + countryCode;
  }

  if (priorityFilter) {
    url = url + "&priority=" + priorityFilter;
  }
  let endpoint = {
    ...apiEndPoints.suggestionsHomePage,
    url: url,
  };
  return httpRequest(endpoint, "get", null, "app");
};
/**
 * Get user Suggestions
 * @param filterValue
 * @param pageNumber
 * @param size
 * @returns
 */
export const getUserSuggestions = (
  filterValue?: any | null,
  pageNumber: number = 1,
  size: number = RECORDS_PER_PAGE,
  countryCode?: string[] | null
) => {
  let url =
    apiEndPoints.getUserSuggestions.url +
    "?pageNo=" +
    pageNumber +
    "&size=" +
    size;
  if (countryCode) {
    url = url + "&countryCode=" + countryCode;
  }
  if (filterValue) {
    url = url + "&status=" + filterValue;
  }
  let endpoint = {
    ...apiEndPoints.getUserSuggestions,
    url: url,
  };
  return httpRequest(endpoint, "get", null, "app");
};
export const getDashboardData = (filterValue?: string) => {
  return httpRequest(apiEndPoints.dashboard, "get");
};

export const updateSuggestionStatus = (inputData: any) => {
  return httpRequest(
    apiEndPoints.updateSuggestionStatus,
    "post",
    inputData,
    "app"
  );
};

export const getUserDetails = () => {
  return httpRequest(apiEndPoints.getUserDetails, "get", null, "app");
};

/**
 * Get campaign Overview component info
 * @returns promise
 */
export const getCampaignOverviewData = (countryCode?: string[] | null) => {
  let endpoint = {
    ...apiEndPoints.campaignOverview,
    url: apiEndPoints.campaignOverview.url  +
    "?country="  + countryCode,
  };
  return httpRequest(endpoint, "get", null, "app");

};

/**
 * Get suggestion target info
 * @returns promise
 */
export const getSuggestionTargetData = (inputValues?: any) => {
  let endpoint = {
    ...apiEndPoints.getSuggestionTargetDetails,
    url: apiEndPoints.getSuggestionTargetDetails.url,
  };
  return httpRequest(endpoint, "post", inputValues, "socketapp");
};

/**
 * Get suggestion shipment info
 * @returns promise
 */
export const getSuggestionShipmentData = (inputValues?: any) => {
  let endpoint = {
    ...apiEndPoints.getSuggestionShipmentDetails,
    url: apiEndPoints.getSuggestionShipmentDetails.url,
  };
  return httpRequest(endpoint, "post", inputValues, "socketapp");
};
/**
 * Removing suggestion ar user level
 * @param id
 * @returns
 */
export const closeSuggestion = (id: number) => {
  let endpoint = {
    ...apiEndPoints.closeSuggestion,
    url: apiEndPoints.closeSuggestion.url + "?suggestionId=" + id,
  };
  return httpRequest(endpoint, "get", null, "app");
};

export const saveToken = (inputData: any) => {
  return httpRequest(apiEndPoints.saveToken, "post", inputData, "app");
};

export const getNotificationDetails = (date: string, emailid: string) => {
  let endpoint = {
    ...apiEndPoints.getNotificationDetails,
    url:
      apiEndPoints.getNotificationDetails.url +
      "?date=" +
      date +
      "&email=" +
      emailid,
  };
  return httpRequest(endpoint, "get", null, "app");
};

export const updateNotificationDetails = (id: any) => {
  let endpoint = {
    ...apiEndPoints.getNotificationDetails,
    url:
      apiEndPoints.getNotificationDetails.url + "?notificationEntityId=" + id,
  };
  return httpRequest(endpoint, "patch", null, "app");
};

export const getNotificationInfo = (date: string, emailid: string) => {
  let endpoint = {
    ...apiEndPoints.getNotificationDetails,
    url:
      apiEndPoints.getNotificationDetails.url +
      "?date=" +
      date +
      "&email=" +
      emailid,
  };
  return httpRequest(endpoint, "get", null, "loader");
};

export const getAppsData = async() => {  
  return httpRequest(apiEndPoints.appsData, "post", null, "socketapp");
};

export const getRecentUpdates = async() => {  
  return httpRequest(apiEndPoints.recentUpdates, "post", null, "socketapp");
};

