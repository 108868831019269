import axios from "axios";
import { BehaviorSubject } from "rxjs";
import { loaderSubject } from "helpers/loaderHelper";
import { getSessionItem } from "./utils/helper";
import { storageKeys } from "./constants";
import { EnvironmentToToken } from "models/shared";
import { apiEndPoints } from "./apiEndpoints";

export const errorSubject = new BehaviorSubject(null);

const requests: any[] = []; // a global array to keep all active http requests
// A mapping of whichever token needs to be passed to each environments
const environmentToTokenMapping: EnvironmentToToken[] = [
  {
    environment: "cdp",
    baseUrl: process.env.REACT_APP_CDP_API_URL || "",
    tokenKey: storageKeys.CDP_TOKEN_ACCESS,
  },
  {
    environment: "app",
    baseUrl: process.env.REACT_APP_API_URL || "",
    tokenKey: storageKeys.KEYCLOAK_TOKEN_ACCESS,
  },
  {
    environment: "socketapp",
    baseUrl: process.env.REACT_APP_SOCKET_ASYNC_URL || "",
    tokenKey: storageKeys.KEYCLOAK_TOKEN_ACCESS,
  },
  {
    environment: "loader",
    baseUrl: process.env.REACT_APP_API_URL || "",
    tokenKey: storageKeys.KEYCLOAK_TOKEN_ACCESS,
  },
];

/**
 * Remove a request from global array once it is completed
 * @param req
 */
const removeRequest = (req: any) => {
  const i = requests.indexOf(req);
  if (i >= 0) {
    requests.splice(i, 1);
  }
  loaderSubject.next(requests.length > 0);
};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    let tokenName = storageKeys.CDP_TOKEN_ACCESS;
    // let envName = "";
    let mainUrl = "";
    environmentToTokenMapping.forEach((environment: EnvironmentToToken) => {
      if (
        config &&
        config.url &&
        config.url?.indexOf(environment.baseUrl) > -1
      ) {
        tokenName = environment.tokenKey;
        //envName = environment.environment;
        mainUrl = config.url;
        if (environment.environment === "cdp") {
          // commenting the user header to test cors issue
          //config.headers.User = getSessionItem(storageKeys.USER_MAIL);
        }
      }
    });
    const token = getSessionItem(tokenName) ? getSessionItem(tokenName) : "";
    config.headers.Authorization = "Bearer " + token;
    requests.push(config); // add request to global request array
    // if (envName !== "loader"){
    //   loaderSubject.next(true); // set loader to true
    // }
    if (mainUrl.indexOf("cdp/campaign/notification") === -1) {
      // alert("The URL contains the string 'URL'");
      loaderSubject.next(true); // set loader to true
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    removeRequest(response.config); // remove request from global request array
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    removeRequest(error.config); // remove request from global request array
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error && error.request && error.request.responseURL && error.response) {
      if (
        apiEndPoints &&
        apiEndPoints.fileUploadCheck &&
        apiEndPoints.fileUploadCheck.url
      ) {
        const parentString = error.request.responseURL;
        const validateAPI = apiEndPoints.fileUploadCheck.url;
        if (parentString.includes(validateAPI)) {
          return error.response;
        }
      }
    } // 500 error is returned from the file validate API for generic error
    // & the error info is required to be shown in the Front end, as the user have to
    // connect with the support team to handle this.
    //No other errors are needed to be thrown in the current scope and
    // that is why the error is returned only for this API.
  }
);
