import { Box } from "@mui/material";
import React, { FC } from "react";
import "./SelectFilter.scss";
import dropdown_arrow_blue from "../../../../../../../src/assets/icons/icon-arrow-down-blue.svg";
import CancelIcon from "@mui/icons-material/Cancel";

type SelectFilterProps = {
  handleExpandFilter: () => void;
  filtersSelected?: Array<string>;
  isExpandFilter: boolean;
  isMandatory?: boolean;
  handleUpdateFilterClick: (filter: string) => void;
};

const SelectFilter: FC<SelectFilterProps> = ({
  handleExpandFilter,
  filtersSelected,
  isExpandFilter,
  isMandatory,
  handleUpdateFilterClick,
}) => {
  return (
    <Box
      className="select-filter-container"
      style={{
        borderColor: isExpandFilter ? "#2D9DE2" : "",
        cursor: "pointer",
      }}
      onClick={handleExpandFilter}
    >
      <Box className="select-filter-wrapper">
        {filtersSelected?.map((filterSelected, i) => (
          <Box key={i}>
            <Box className="filter-selected-wrapper">
              <Box className="filter-selected-name">{filterSelected}</Box>
              {!isMandatory && (
                <CancelIcon
                  onClick={() => handleUpdateFilterClick(filterSelected)}
                  cursor="pointer"
                  style={{
                    width: "18px",
                    color: "#a2abb1",
                    paddingRight: "4px",
                  }}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Box className="border-chevron-wrapper">
        <img
          src={dropdown_arrow_blue}
          alt="Gray icon"
          className="arrow-down"
          style={{ transform: isExpandFilter ? "rotate(180deg)" : "" }}
        />
      </Box>
    </Box>
  );
};

export default SelectFilter;
