import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from "i18next-http-backend";

i18n

    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(HttpApi)
    // init i18next
    .init({
        debug: false,
        fallbackLng: 'en',

        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json"
        }
    });

export default i18n;