import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import Routes from "routes/Routes";
import { Header } from "components/core/Header";
import "./Layout.scss";
// import { Breadcrumb } from "../Breadcrumb";
import { useLocation } from "react-router-dom";
import { GlobalMessage } from "../GlobalMessage";
import { NewHeader } from "components/core/Header/NewHeader";
import { Notification } from "components/Notification";

export function Layout() {
  const routePath = useLocation();
  const refContainer = useRef<any>();
  const [isNewHomePage, setIsNewHomePage] = useState(false);
  const [isTargetPage, setIsTargetPage] = useState(false);

  useEffect(() => {
    if (refContainer.current) {
      refContainer.current.scrollTop = 0;
    }
    if (
      routePath &&
      routePath.pathname &&
      (routePath.pathname === "/targetPage/" || routePath.pathname === "/targetAppDashboard/")
    ) {
      setIsTargetPage(true);
    } else if (
      routePath &&
      routePath.pathname &&
      routePath.pathname === "/home"
    ) {
      setIsNewHomePage(true);
    } else if (
      routePath &&
      routePath.pathname &&
      routePath.pathname.includes("/appsInfo/")
    ) {
      setIsNewHomePage(true);
    } else {
      setIsNewHomePage(false);
    }
  }, [routePath]);
  const globalErr = process.env.REACT_APP_GLOBAL_ERR_MSG;

  return (
    <>
      {isTargetPage && !isNewHomePage && (
        <div>
          
           <Notification/>
          <Routes />
        </div>
      )}
      {!isNewHomePage && !isTargetPage && (
        <Grid container className="layout-container">
          {/* Alert box */}
          {/* <Toast position="top-space" closeIcon={true} alert={true}>
        {t("alert_message")}
      </Toast> */}
          <Grid item xs={12} className="header-container">
            <Header />
          </Grid>
          {/* <Grid item xs={12} className="breadcrumb-container">
            <Breadcrumb />
          </Grid> */}

          <Grid
            item
            md={12}
            xs={12}
            className="right-container custom-scroll"
            ref={refContainer}
          >
            {globalErr && <GlobalMessage></GlobalMessage>}

            <Grid container>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                className="page-container"
              >
                <Grid item md={12} lg={12} xl={12} xs={12}>
                  <Routes />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isNewHomePage && !isTargetPage && (
        <>
          <div className="header-container-new">
            <NewHeader />
          </div>
          <div className="apps-container-iframe">
            <Routes />
          </div>
        </>
      )}
    </>
  );
}
