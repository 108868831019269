import React, { useEffect, useState } from "react";
import { Grid, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { loaderSubject } from "helpers/loaderHelper";
import "./Loader.scss";

export function Loader() {
  const [showLoader, displayLoader] = useState<boolean>(false);

  useEffect(() => {
    loaderSubject.subscribe((value: boolean) => {
      displayLoader(value);
    });
  }, []);

  return showLoader ? (
    <Grid className="loader-parent">
      <Grid className="overlay">
        <Grid className="spinner">
          <Box>
            <CircularProgress color="primary" size={60} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}
