import { Box } from "@mui/material";
import { OperatorType } from "pages/TargetV2/types/targetV2.types";
import React, { FC, useEffect, useRef } from "react";
import dropdown_arrow_blue from "../../../../../../../../src/assets/icons/icon-arrow-down-blue.svg";
import DropDownSelect from "../dropdownSelect/DropDownSelect";

type SelectOperatorDropDownProps = {
  values: Array<string>;
  index: number;
  handleUpdateOperatorClick: (operator: string, index?: number) => void;
  expandedFilterOfOperator: boolean;
  setExpandedFilterOfOperator: (isExpand: boolean) => void;
  operator?: OperatorType | null;
};
const SelectOperatorDropDown: FC<SelectOperatorDropDownProps> = ({
  values,
  index,
  handleUpdateOperatorClick,
  expandedFilterOfOperator,
  setExpandedFilterOfOperator,
  operator,
}) => {
  const refDropdown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        refDropdown.current &&
        !refDropdown.current.contains(e.target) &&
        expandedFilterOfOperator
      ) {
        setExpandedFilterOfOperator(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setExpandedFilterOfOperator, expandedFilterOfOperator]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      ref={refDropdown}
      style={{ cursor: "pointer" }}
      onClick={() => setExpandedFilterOfOperator(!expandedFilterOfOperator)}
    >
      <Box display="flex" gap="6px">
        <Box className="operator-select-wrapper">
          <Box className="operator-group-select">{operator ?? "And"}</Box>
        </Box>
        <img
          src={dropdown_arrow_blue}
          alt="Dropdown icon"
          className="arrow-down"
          style={{
            border: "1px solid #2d9de2",
            cursor: "pointer",
            transform: expandedFilterOfOperator ? "rotate(180deg)" : "",
          }}
        />
      </Box>

      <DropDownSelect
        values={values}
        isExpandFilter={expandedFilterOfOperator}
        handleClick={handleUpdateOperatorClick}
        index={index}
      />
    </Box>
  );
};

export default SelectOperatorDropDown;
