import { Box } from "@mui/material";
import React, { FC } from "react";
import "../SelectFilter.scss";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { format } from "date-fns";

type SelectSimpleDateProps = {
  handleExpandDateRange: () => void;
  startDate?: Date | string;
  endDate?: Date | string;
  isExpandDateRange: boolean;
};

const SelectSimpleDate: FC<SelectSimpleDateProps> = ({
  handleExpandDateRange,
  startDate,
  endDate,
  isExpandDateRange,
}) => {
  return (
    <Box
      className="select-filter-container"
      width="100%"
      style={{
        borderColor: isExpandDateRange ? "#2D9DE2" : "",
        cursor: "pointer",
      }}
      onClick={handleExpandDateRange}
    >
      <Box className="select-filter-wrapper" position="relative">
        {startDate && endDate && (
          <Box className="filter-selected-wrapper">
            <Box className="filter-selected-name">
              <Box>
                {startDate instanceof Date
                  ? format(startDate, "dd/MM/yyyy")
                  : new Date(startDate).toLocaleDateString()}{" "}
                -{" "}
                {endDate instanceof Date
                  ? format(endDate, "dd/MM/yyyy")
                  : new Date(endDate).toLocaleDateString()}
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <CalendarTodayIcon
        style={{ paddingRight: "12px", color: "#24a3bc", cursor: "pointer" }}
      />
    </Box>
  );
};

export default SelectSimpleDate;
