import { AxiosResponse } from "axios";
import { apiEndPoints } from "core/apiEndpoints";
import { httpRequest } from "core/httpRequest";
import {
  ResFilterList,
  SubGroupFilterItem,
  createdTarget,
  CreateFilterItem,
  targetUserInfo,
} from "pages/TargetV2/types/targetV2.types";


export const getFiltersData = async (userData: targetUserInfo): Promise<
  AxiosResponse<ResFilterList> | undefined
> => {

  return httpRequest(
    apiEndPoints.targetV2Filters,
    "post",
    userData,
    "socketapp"
  );
};

export const postFilterValues = async (
  targetList: SubGroupFilterItem
): Promise<AxiosResponse<SubGroupFilterItem> | undefined> => {
  return httpRequest(
    apiEndPoints.targetV2FilterValues,
    "post",
    targetList,
    "socketapp"
  );
};

export const postVolumesCountHCPS = async (
  targetList: SubGroupFilterItem
): Promise<AxiosResponse<SubGroupFilterItem> | undefined> => {
  return httpRequest(
    apiEndPoints.targetV2Volumes,
    "post",
    targetList,
    "socketapp"
  );
};

export const postTargetList = async (
  targetList: CreateFilterItem
): Promise<AxiosResponse<createdTarget> | undefined> => {
  return httpRequest(
    apiEndPoints.targetV2Create,
    "post",
    targetList,
    "socketapp"
  );
};
