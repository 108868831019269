import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Auth from "core/authenticator";
import { setUserPreference } from "../src/core/utils/helper";
import "./index.scss";
import "services/i18n";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { HashRouter as Router } from "react-router-dom";


setUserPreference();
const auth: any = new Auth();

/**
 * Initialize keycloak authentication
 */
const initAuth = () => {
  const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
  const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM;
  const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
  const silentSSOFileUrl = null;
 
  auth.init({
    clientId: clientId,
    silentSSOFileUrl: silentSSOFileUrl,
    realm: keycloakRealm,
    keycloakUrl: keycloakUrl,
  });
};

initAuth();

auth?.events?.once?.("auth-success", () => {
  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <Router>
          <App />
        </Router>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
