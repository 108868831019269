import { Box } from "@mui/material";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import React, { useCallback } from "react";
import "./SearchFilter.scss";
import search_filter from "../../../../../src/assets/icons/icon-search.svg";
import { useTranslation } from "react-i18next";

const SearchFilter = () => {
  const { filtersDropdownData, setIsSearching, setSearchingFiltersResults } =
    useTargetV2Context();

  const { t } = useTranslation();

  const handleSearchFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setIsSearching(false);
      } else {
        setIsSearching(true);
        const value = e.target.value.toLowerCase().trim();

        const tempState = [...filtersDropdownData];

        const filteredList = tempState.map((category) => {
          return {
            ...category,
            filterItemsChildren: category.filterItemsChildren.filter((filter) =>
              filter.name.toLowerCase().includes(value)
            ),
          };
        });

        setSearchingFiltersResults([...filteredList]);
      }
    },
    [filtersDropdownData, setSearchingFiltersResults, setIsSearching]
  );
  return (
    <Box className="input-wrapper">
      <Box className="input-box">
        <input
          className="input-section"
          onChange={(e) => handleSearchFilter(e)}
          placeholder={t("search_for_a_filter")}
        />

        <img
          src={search_filter}
          alt="search icon"
          style={{ paddingRight: "10px" }}
        />
      </Box>
    </Box>
  );
};

export default SearchFilter;
