import React from "react";
import "./TargetV2Footer.scss";
import { Box, Grid } from "@mui/material";
import { Button } from "components/shared/Button";
import { useTranslation } from "react-i18next";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";

const TargetV2Footer = (props: any) => {
  const { t } = useTranslation();
  const { targetListData } = useTargetV2Context();

  return (
    <Grid className="footer-container">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            type="small"
            variant="secondary"
            isDisabled={props.isApplyingFilters && targetListData[0].filterItemsChildren.length > 0 ? "notDisabled" : "disabled"}
            onClick={props.handleSaveTarget}
         >
            {t("save_target")}
          </Button>
        </Box>
        <Box display="flex" gap="12px">
          {props.userRole === "Super User" && (
          <Button
            type="small"
            variant="secondary"
            isDisabled={targetListData[0].filterItemsChildren.length > 0}
          >
            {t("move_to_simulate")}
          </Button>
        )}
          <Button
            type="small"
            variant="primary"
            // isDisabled={targetListData[0].filterItemsChildren.length > 0}
            isDisabled={props.isApplyingFilters && targetListData[0].filterItemsChildren.length > 0 ? "notDisabled" : "disabled"}
            onClick={props.moveToShipment}
          >
            {t("move_to_shipment")}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default TargetV2Footer;
