import { Box, Grid } from "@mui/material";
import { CheckBoxNormal } from "components/shared/CheckBoxNormal";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";

type DropDownSelectFilterProps = {
  filters: string[];
  isMulti?: boolean;
  filtersSelected: string[];
  handleUpdateFilter: (filter: string) => void;
};
const DropDownSelectFilter: FC<DropDownSelectFilterProps> = ({
  filters,
  isMulti,
  filtersSelected,
  handleUpdateFilter,
}) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchingFilterResults, setSearchingFiltersResults] = useState<
    Array<string>
  >([]);

  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setIsSearching(false);
      } else {
        setIsSearching(true);
        const value = e.target.value.toLowerCase().trim();

        const tempState = [...filters];

        const filteredList = tempState.filter((selectFilter) =>
          selectFilter.toLowerCase().includes(value)
        );
        setSearchingFiltersResults([...filteredList]);
      }
    },
    [setSearchingFiltersResults, setIsSearching, filters]
  );

  const filtersList = useMemo(() => {
    return isSearching ? searchingFilterResults : filters;
  }, [filters, isSearching, searchingFilterResults]);

  const resetValue = useCallback(() => {
    setIsSearching(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, [setIsSearching]);

  return (
    <Grid container className={`dropdownbox-container`}>
      <Box className="dropdownbox-multiselect">
        <div
          id="multidropdown-options"
          className="dropdown-options custom-scroll"
        >
          <div
            className="dropdownmulti-option-item"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <input
              ref={inputRef}
              placeholder={t("search_for_a_filter")}
              onChange={handleSearchFilter}
              style={{
                outline: "none",
                border: "none",
                background: "transparent",
                boxShadow: "none",
                width: "100%",
              }}
            />
            {isSearching ? (
              <Close
                style={{ color: "#848484", opacity: 1, cursor: "pointer" }}
                onClick={resetValue}
              />
            ) : (
              <SearchIcon
                style={{ color: "#848484", opacity: 1, cursor: "pointer" }}
              />
            )}
          </div>
          <div className="options custom-scroll">
            {filtersList.map((filter, i) => (
              <div
                className="dropdownmulti-option-item"
                data-value={filter}
                data-label={filter}
                onClick={() => handleUpdateFilter(filter)}
                key={`filter-${filter}-${i}`}
                style={{ cursor: "pointer" }}
              >
                {isMulti && (
                  <CheckBoxNormal
                    checked={filtersSelected.some(
                      (filterSelected) => filterSelected === filter
                    )}
                    className="dropdown-checkbox"
                  />
                )}
                <label>{filter}</label>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </Grid>
  );
};

export default DropDownSelectFilter;
