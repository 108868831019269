import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Button } from "components/shared/Button";
import { Popup } from "components/shared/Popup";
import { errorSubject } from "core/httpInterceptors";
import { Trans, useTranslation } from "react-i18next";
import { firbaseResponseState } from "recoil/atoms";
import "./ErrorPopup.scss";
import { useRecoilValue } from "recoil";

export function ErrorPopup() {
  const firbaseResponse = useRecoilValue(firbaseResponseState);
  const { t } = useTranslation();
  const [popupOpen, setPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleClose = () => {
    setPopup(false);
  };
  useEffect(() => {
    errorSubject.subscribe((error: any) => {
      if (error !== null) {
        setPopup(true);
        const errMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.message
            ? error.response.data.error.message
            : "api_error_text";
        setErrorMsg(errMsg);
      }
    });
  }, []);
  /**
   * to populate target result from firebase response
   */
  useEffect(() => {
    if (firbaseResponse && firbaseResponse.data) {
      let response = JSON.parse(firbaseResponse.data.response);
      if (response.error_code) {
        setPopup(true);
        const errMsg = response.details ? response.details : "api_error_text";
        setErrorMsg(errMsg);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firbaseResponse]);
  return popupOpen ? (
    <Popup
      title={t("error_title")}
      open={popupOpen}
      type="error"
      class="error-popup-wrapper"
      headerIcon="icon-alert"
      handleClose={handleClose}
    >
      <Grid container direction="column">
        <Grid item className="alert-body">
          <Typography className="alert-text">
            <Trans i18nKey={errorMsg}></Trans>
          </Typography>
        </Grid>
        <Grid item className="btn-align">
          <Button variant="primary" onClick={handleClose} type="small">
            {t("okay")}
          </Button>
        </Grid>
      </Grid>
    </Popup>
  ) : null;
}
