import React, { useEffect, useState } from "react";
import Filter from "./filter/Filter";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import { Popup } from "components/shared/Popup";

const CustomFiltersContainer = (props: any) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [customUrl, setCustomUrl] = useState("");

  useEffect(() => {
    if (props.targetListCsvProcessingUrl) {
      const segements = props.targetListCsvProcessingUrl
        .split("/")
        .filter((segment: any) => segment);
      const lastSegment = segements[segements.length - 1];
      setCustomUrl(lastSegment);
    }
  }, [props.targetListCsvProcessingUrl]);

  const openIframePopup = () => {
    customUrl ? setOpenPopup(true) : setOpenPopup(false);
  };
  const closeIframePopup = () => {
    setOpenPopup(false);
  };
  return (
    <>
      <Grid onClick={openIframePopup} style={{ color: "red;" }}>
        <Filter
          name="Add your customised filter"
          color={props.targetListCsvProcessingUrl ? "#2D9DE2" : " #969696"}
          bgColor="#F5FFFF"
          icon={
            <AddIcon
              style={
                props.targetListCsvProcessingUrl
                  ? { color: "#2D9DE2" }
                  : { color: "#969696" }
              }
            />
          }
          isPointer={props.targetListCsvProcessingUrl ? true : false}
        />
      </Grid>
      <Filter
        name="Add HCPs to the target list"
        color="#000000"
        bgColor="#E1F8F7"
      />
      <Filter
        name="Remove HCPs to the target list"
        color="#000000"
        bgColor="#F8ECE1"
      />

      <Popup
        open={openPopup}
        headerIcon="icon-popup-info"
        class="send-sfmc-popup-wrapper"
        handleClose={closeIframePopup}
      >
        <Grid item>
          <iframe
            title={"Csv url"}
            height="1058"
            // src={`https://dev-admin.precisionai-portal.bayer.com:8093/?method=post&bucketname=pai-backend-api-csv-development&filename=targets_details/${customUrl}`}
            src={`${process.env.REACT_APP_CUSTOM_FILTER_URL}?method=post&bucketname=${process.env.REACT_APP_AWS_BUCKET}&filename=targets_details/${customUrl}`}
            width="100%"
            style={{ border: "none", overflow: "hidden" }}
          />
        </Grid>
      </Popup>
    </>
  );
};

export default CustomFiltersContainer;
