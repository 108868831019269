import { Box } from "@mui/material";
import React, { FC } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import { useTranslation } from "react-i18next";
import { SubGroupFilterItem } from "pages/TargetV2/types/targetV2.types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type NewGroupFooterBarProps = {
  index: number;
  subGroupFilterItemChildren: SubGroupFilterItem;
};
const NewGroupFooterBar: FC<NewGroupFooterBarProps> = ({
  index,
  subGroupFilterItemChildren,
}) => {
  const { handleCreateNewGroup, handleReplicatePreviousGroup } =
    useTargetV2Context();

  const { t } = useTranslation();

  return (
    <Box className="button-action-container">
      <Box className="new-group-title">Group {index + 2}</Box>
      <Box display="flex" alignItems="center" gap="8px">
        <Box className="button-action-wrapper">
          <Box
            className="button-action-with-icon-wrapper"
            onClick={handleCreateNewGroup}
          >
            <AddIcon
              style={{
                width: "24px",
                height: "24px",
                color: "#2D9DE2",
              }}
            />
            <Box className="button-action-title">{t("start_new_group")}</Box>
          </Box>
          <Box className="line-between" />
          <Box
            className="button-action-with-icon-wrapper"
            onClick={() =>
              handleReplicatePreviousGroup(subGroupFilterItemChildren)
            }
          >
            <ContentCopyIcon
              style={{
                width: "24px",
                height: "24px",
                color: "#2D9DE2",
              }}
            />
            <Box className="button-action-title">
              {t("replicate_previous_group")}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default NewGroupFooterBar;
