import React from "react";
import { Box }from '@mui/material';
import "./ImageIcon.scss";

export function ImageIcon(props: any) {
  const { classes, onClick, ...rest } = props;
  return (
    <Box className={`image-icon ${classes}`} onClick={onClick} {...rest}></Box>
  );
}
