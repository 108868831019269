const session = localStorage;

export function getSessionItem(key: any) {
  return session.getItem(key);
}
export function setSessionItem(key: any, value: any) {
  return session.setItem(key, value);
}
export function removeSessionItem(key?: any) {
  if (key) {
    return session.removeItem(key);
  } else {
    return session.clear();
  }
}

// session storage fuctions
export function getSessionStorageItem(key: any) {
  return sessionStorage.getItem(key);
}
export function setSessionStorageItem(key: any, value: any) {
  return sessionStorage.setItem(key, value);
}
export function removeSessionStorageItem(key?: any) {
  if (key) {
    return sessionStorage.removeItem(key);
  } else {
    return sessionStorage.clear();
  }
}
// Function to set user consent if not already set
export function setUserPreference() {
  const userPreferenceGA = localStorage.getItem("userConsent_GA");
  if (!userPreferenceGA) {
    localStorage.setItem("userConsent_GA", "yes");
  }
  return true;
}
