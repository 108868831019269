import AWS from "aws-sdk";
import { fileUploadKeys, DATE_TIME_FORMAT_UPLOAD } from "core/constants";
import { dateFormatConvertor } from "./sharedHelper";

AWS.config.update({
 region: process.env.REACT_APP_AWS_CONFIG_REGION || "",
  accessKeyId: process.env.REACT_APP_AWS_CONFIG_ACCESS_KEY_ID || "",
  secretAccessKey: process.env.REACT_APP_AWS_CONFIG_SECRET_ACCESS_KEY || "",
});
const handleClick = (files: any, source: any) => {
  let file = files ? files[0] : { name: "", type: "" };

  //sets unique file name to the uploaded file
  let fileName = file.name;
  let fileExt = fileName.split(".").pop();
  let formattedDate = dateFormatConvertor(new Date(), DATE_TIME_FORMAT_UPLOAD);

  let newFileName =
    fileUploadKeys.TARGETS_DETAILS +
    "/" +
    fileUploadKeys.TARGETS_DETAILS +
    "_" +
    process.env.REACT_APP_ENV_NAME +
    "_" +
    formattedDate +
    "." +
    fileExt;
  const params = {
    Key: newFileName,
    ContentType: file.type,
    Body: file,
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
  };
  return params;
};

const handleDownload = (uri: any) => {
  const downloadFile = uri
    ? uri.split("s3://" + process.env.REACT_APP_AWS_BUCKET + "/")[1]
    : "";
  const s3 = new AWS.S3();
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
    Key: downloadFile,
  };

  function downloadBlob(blob: any, name: any = downloadFile) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement("a");
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name ? name : "";
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  s3.getObject(params, (err, data) => {
    if (err) {
      console.log(err, err.stack);
    } else {
      const dataBody = data && data.Body ? data.Body.toString() : "";
      let csvBlob = new Blob([dataBody], {
        type: "text/csv;charset=utf-8;",
      });
      downloadBlob(csvBlob, downloadFile);
    }
  });
};

const getSingedUrlFile = async (fileURL: any) => {
  const downloadFile = fileURL
    ? fileURL.split("s3://" + process.env.REACT_APP_AWS_BUCKET + "/")[1]
    : "";
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
    Key: downloadFile,
    Expires: 86400,
  };
  const s3 = new AWS.S3();  
  try {
    const url = await new Promise((resolve, reject) => {
      s3.getSignedUrl("getObject", params, (err, url) => {
        err ? reject(err) : resolve(url);
      });
    });
    return url;
  } catch (err) {
    if (err) {
      return null;
    }
  }
};

export { handleClick, handleDownload, getSingedUrlFile };
