import axios from "axios";
import "core/httpInterceptors";
import { DataSources } from "models/shared";

const baseUrlMapper: DataSources = {
  cdp: process.env.REACT_APP_CDP_API_URL || "",
  app: process.env.REACT_APP_API_URL || "",
  suggestions: process.env.REACT_APP_MOCK_API_URL || "",
  socketapp: process.env.REACT_APP_SOCKET_ASYNC_URL || "",
  loader: process.env.REACT_APP_API_URL || "",
};

/**
 * Common method through which all API requests are passed
 * @param endPoint
 * @param method
 * @param data
 * @param dataSource
 * @returns
 */
export function httpRequest(
  endPoint: any,
  method: string,
  data?: any,
  dataSource: keyof DataSources = "cdp",
  config=false
) {

  const isMock = endPoint && endPoint.isMock ? endPoint.isMock : false;
  const requestMethod = isMock ? "get" : method;
  // choose between mock, cdp or app api url
  const baseUrl = isMock
    ? process.env.REACT_APP_MOCK_API_URL
    : baseUrlMapper[dataSource];

  const requestUrl = (isMock && endPoint && endPoint.url) ? endPoint.url :
    endPoint && endPoint.url ? baseUrl + endPoint.url : baseUrl;
  switch (requestMethod) {
    case "get":
      return get(requestUrl,config);
    case "post":
      return post(requestUrl, data);
    case "put":
      return put(requestUrl, data);
    case "patch":
      return patch(requestUrl, data);
  }
}

function get(url: string, config: any) {
  if(config){
    return axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
  });
  }
  return axios.get(url);
}

function post(url: string, data: any) {
  return axios.post(url, data);
}

function put(url: string, data: any) {
  return axios.put(url, data);
}

function patch(url: string, data: any) {
  return axios.patch(url, data);
}
