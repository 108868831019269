import {
  SimulatorListItem,
  SuggestionListItem,
  campaignOverviewItem,
} from "models/home";
import { dateFormatConvertor } from "helpers/sharedHelper";
import { DATE_FORMAT_CLIENT, asyncCAllMessages } from "core/constants";

/**
 * Format data from API for home page simulator
 * @param data
 * @returns array
 */
export const formatSimulatorInfo = (data: any): SimulatorListItem[] => {
  let returnData: SimulatorListItem[] = [];
  if (data && Array.isArray(data)) {
    returnData = data.map((simulatorData: any) => {
      return {
        id: simulatorData.id ? simulatorData.id : 0,
        plannedCampaign: simulatorData.plannedCampaign
          ? simulatorData.plannedCampaign
          : "",
        noOfAccounts: simulatorData.noOfAccounts
          ? simulatorData.noOfAccounts
          : 0,
        noOfSalesReps: simulatorData.noOfSalesReps
          ? simulatorData.noOfSalesReps
          : 0,
        status: {
          id:
            simulatorData.status && simulatorData.status.id
              ? simulatorData.status.id
              : 0,
          statuslabel:
            simulatorData.status && simulatorData.status.statuslabel
              ? simulatorData.status.statuslabel
              : "",
        },
      };
    });
  }
  return returnData;
};

export const formatSuggestionData = (suggestionData: any) => {
  return {
    id: suggestionData.id ? suggestionData.id : 0,
    description: suggestionData.description ? suggestionData.description : "",
    theme: suggestionData.theme ? suggestionData.theme : "",
    country: suggestionData.country ? suggestionData.country : "",
    businessRationale: suggestionData.businessRationale
      ? suggestionData.businessRationale
      : "",
    insight: suggestionData.insightText ? suggestionData.insightText : [],
    action: suggestionData.actionText ? suggestionData.actionText : "",
    expiresOn: suggestionData.expiresOn
      ? dateFormatConvertor(
          new Date(suggestionData.expiresOn),
          DATE_FORMAT_CLIENT
        )
      : "",
    priority: suggestionData.priority ? suggestionData.priority : "",
    status: suggestionData.status ? suggestionData.status : "",
    modifiedTime: suggestionData.modifiedTime
      ? suggestionData.modifiedTime
      : "",
    new: suggestionData.new ? suggestionData.new : false,
  };
};

/**
 * Format data from API for home page suggestions
 * @param data
 * @returns array
 */
export const formatSuggestionsInfo = (data: any): SuggestionListItem[] => {
  let returnData: SuggestionListItem[] = [];
  if (data && data.suggestions && Array.isArray(data.suggestions)) {
    returnData = data.suggestions.map((suggestionData: any) => {
      return formatSuggestionData(suggestionData);
    });
  }
  return returnData;
};
/*
 * Format data from API for home page Campaign Overview
 * @param data
 * @returns array
 */
export const formatCampaignOverviewInfo = (
  data: any
): campaignOverviewItem | null => {
  let returnData: campaignOverviewItem | null = null;
  if (data) {
    returnData = {
      campaignTargetCount: data.campaignTargetCount
        ? data.campaignTargetCount
        : 0,
      activatedCampaignTargetCount: data.activatedCampaignTargetCount
        ? data.activatedCampaignTargetCount
        : 0,
      hcpCount: data.hcpCount ? data.hcpCount : 0,
      microsegments: data.microsegments ? data.microsegments : 0,
    };
  }
  return returnData;
};
/**
 * returning message for push notifications
 * @param payload 
 * @returns 
 */
export const getMessage = (operationType: any, isError: any): string | null => {
  switch (operationType) {
    case "TARGETS_TOTAL_COUNT":
      return isError ? asyncCAllMessages.TARGETS_TOTAL_COUNT_ERR: asyncCAllMessages.TARGETS_TOTAL_COUNT;
    case "TARGETS_SIMULATION":
      return isError ? asyncCAllMessages.TARGETS_TOTAL_COUNT_ERR: asyncCAllMessages.TARGETS_SIMULATION;
    case "TARGETS_SHIPMENT_DETAILS_SFMC":
    return isError ? asyncCAllMessages.TARGETS_SHIPMENT_DETAILS_SFMC_ERR : asyncCAllMessages.TARGETS_SHIPMENT_DETAILS_SFMC;
    case "TARGETS_SHIPMENT_DETAILS_LOCAL":
    return isError ? asyncCAllMessages.TARGETS_SHIPMENT_DETAILS_LOCAL_ERR : asyncCAllMessages.TARGETS_SHIPMENT_DETAILS_LOCAL;  
    case "TARGETS_SIMULATION_DETAILS_LOCAL":
    return isError ? asyncCAllMessages.TARGETS_SIMULATION_DETAILS_LOCAL_ERR : asyncCAllMessages.TARGETS_SIMULATION_DETAILS_LOCAL;
    case "SUGGESTION_TARGET_EXPORT_DETAIL" :
    return isError ? asyncCAllMessages.SUGGESTION_TARGET_EXPORT_DETAIL_ERR : asyncCAllMessages.SUGGESTION_TARGET_EXPORT_DETAIL;
    case "SUGGESTION_SHIPMENT_EXPORT_DETAIL" :
    return isError ? asyncCAllMessages.SUGGESTION_SHIPMENT_EXPORT_DETAIL_ERR : asyncCAllMessages.SUGGESTION_SHIPMENT_EXPORT_DETAIL;  
    case "TARGETS_IMPACT" :
    return isError ? asyncCAllMessages.TARGETS_IMPACT_ERR : asyncCAllMessages.TARGETS_IMPACT;  
    case "TARGETS_IMPACT_TOUCHPOINT" :
    return isError ? asyncCAllMessages.TARGETS_IMPACT_TOUCHPOINT_ERR : asyncCAllMessages.TARGETS_IMPACT_TOUCHPOINT;    
    case "TARGETS_HOME_SFMC" :
    return isError ? asyncCAllMessages.TARGETS_HOME_SFMC_ERR : asyncCAllMessages.TARGETS_HOME_SFMC;  
    case "TARGETS_CSV_EXPORT_LOCAL" :
    return isError ? asyncCAllMessages.TARGETS_CSV_EXPORT_LOCAL_ERR : "";        
    default:
      return null;
  }
};
