import { Box } from "@mui/material";
import { OperatorType } from "pages/TargetV2/types/targetV2.types";
import React, { FC } from "react";

type OperatorLineProps = {
  operator: OperatorType;
  width: string;
};
const OperatorLine: FC<OperatorLineProps> = ({ operator, width }) => {
  return (
    <Box display="flex" alignItems="center" marginBottom="12px">
      <Box className="operator-button">{operator}</Box>

      <Box bgcolor="#D0D0D0" height="1px" width={width} marginLeft="22px" />
    </Box>
  );
};

export default OperatorLine;
