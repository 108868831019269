export type FilterItem = {
  isRecommended?: boolean;
  id: number; //id from db
  filterId: number; // id used for manipulate filters in front end
  name: string;
  filters: Array<string>;
  filtersSelected: Array<string>;
  groupFilter?: SubGroupFilterItem;
  isMandatory?: boolean;
  startDateSelected?: Date | string;
  endDateSelected?: Date | string;
  countHCPS?: number | null;
  isResponsive: boolean;
  operatorBetweenFiltersSelected?: OperatorType | null;
  multiSelect?: boolean;
};

export enum OperatorType {
  AND = "And",
  OR = "Or",
  NOTIN = "And Not",
}

export enum GroupType {
  SURVEY = "SURVEY",
  PAST_INTERACTION = "PAST_INTERACTION",
  CLASSIC = "CLASSIC",
  MCCP = "MCCP",
}

export type SubGroupFilterItem = {
  id: number; //id from db
  subGroupId: number; // id used for manipulate subgroup in front end
  name: string;
  operator: OperatorType; // operator used inside the subgroup
  operatorsBetweenGroups?: Array<OperatorType>; // array of operators used in grandparent subgroup (highest level of targetListData), the index is used for find the operator between the subgroups
  parentId: null | number; // id based on subGroupId of parent
  filterItemsChildren: Array<FilterItem>;
  subgGroupFilterItemsChildren: Array<SubGroupFilterItem>;
  countHCPS?: number | null;
  groupType: GroupType;
  isMCCPGroup?: boolean;
};
export type CreateFilterItem = {
  filterValues: SubGroupFilterItem;
  targetId: any;
};
export type FilterList = {
  id: number;
  name: string;
  filterItemsChildren: Array<FilterItem>;
};

export type ResFilterList = {
  country_list: Array<string>;
  filter_category_list: Array<{
    id: number;
    name: string;
    filterItemsChildren: FilterItem[];
  }>;
};

export type createdTarget = {
  status: string;
  message: string;
  data: {
    uri: string;
    preview_uri:string;
    target_id: number;
  };
};

export type targetUserInfo = {
  emailId: string;
};
