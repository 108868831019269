import { Box } from "@mui/material";
import React, { FC, SetStateAction, useEffect, useRef } from "react";
import DropDownSelect from "../dropdownSelect/DropDownSelect";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type DropDownHeaderMenuProps = {
  isExpandDropDownMenu: boolean;
  setIsExpandDropDownMenu: React.Dispatch<SetStateAction<boolean>>;
  index: number;
  handleHeaderMenuOfGroupClick: (
    value: string,
    indexOfGroups?: number,
    indexOfValue?: number
  ) => void;
};
const DropDownHeaderMenu: FC<DropDownHeaderMenuProps> = ({
  isExpandDropDownMenu,
  setIsExpandDropDownMenu,
  index,
  handleHeaderMenuOfGroupClick,
}) => {
  const refDropdownHeaderGroupMenu = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        refDropdownHeaderGroupMenu.current &&
        !refDropdownHeaderGroupMenu.current.contains(e.target)
      ) {
        setIsExpandDropDownMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandDropDownMenu]);

  return (
    <Box
      ref={refDropdownHeaderGroupMenu}
      style={{ position: "relative", display: "flex" }}
    >
      <MoreVertIcon
        onClick={() => setIsExpandDropDownMenu(!isExpandDropDownMenu)}
        style={{
          border: "1px solid #637e88",
          borderRadius: "50%",
          color: "#637e88",
          width: "24px",
          cursor: "pointer",
        }}
      />

      <DropDownSelect
        values={["Delete the group", "Replicate the group"]}
        isExpandFilter={isExpandDropDownMenu}
        handleClick={handleHeaderMenuOfGroupClick}
        index={index}
        style={{ right: "0px", width: "200px", top: "30px" }}
      />
    </Box>
  );
};

export default DropDownHeaderMenu;
