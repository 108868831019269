import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "core/constants";
import TargetV2 from "pages/TargetV2/TargetV2";

const NewHome = React.lazy(() =>
  import("pages/Home/Home-new").then(({ NewHome }) => ({
    default: NewHome,
  }))
);
const Home = React.lazy(() =>
  import("pages/Home").then(({ Home }) => ({
    default: Home,
  }))
);

const Target = React.lazy(() =>
  import("pages/Target").then(({ Target }) => ({
    default: Target,
  }))
);

const Shipment = React.lazy(() =>
  import("pages/Shipment").then(({ Shipment }) => ({
    default: Shipment,
  }))
);

const TargetFilter = React.lazy(() =>
  import("pages/TargetFilter").then(({ TargetFilter }) => ({
    default: TargetFilter,
  }))
);

const Suggestions = React.lazy(() =>
  import("pages/Suggestions/Suggestions").then(({ Suggestions }) => ({
    default: Suggestions,
  }))
);

const SsampleComponents = React.lazy(() =>
  import("pages/SampleComponents").then(({ SampleComponents }) => ({
    default: SampleComponents,
  }))
);
// const Simulate = React.lazy(() =>
//   import("pages/Simulate").then(({ Simulate }) => ({
//     default: Simulate,
//   }))
// );
const Dashboard = React.lazy(() =>
  import("pages/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);
const ColumnChart = React.lazy(() =>
  import("pages/ChartSamples").then(({ ChartSamples }) => ({
    default: ChartSamples,
  }))
);
// const Impact = React.lazy(() =>
//   import("pages/Impact").then(({ Impact }) => ({
//     default: Impact,
//   }))
// );
const Privacy = React.lazy(() =>
  import("pages/Privacy").then(({ Privacy }) => ({
    default: Privacy,
  }))
);

// const Apps = React.lazy(() =>
//   import("pages/Apps").then(({ Apps }) => ({
//     default: Apps,
//   }))
// );

const AppsDetails = React.lazy(() =>
  import("pages/Apps/AppsDetails").then(({ AppsDetails }) => ({
    default: AppsDetails,
  }))
);

const AppsInfo = React.lazy(() =>
  import("pages/AppsInfo").then(({ AppsInfo }) => ({
    default: AppsInfo,
  }))
);

function Routes() {
  return (
    <React.Suspense fallback="Loading...">
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.home} />
        </Route>
        {/* <Route path={routes.home} exact component={Home} /> */}
        <Route path={routes.home} exact component={NewHome} />

        <Route path={routes.suggestions} exact component={Suggestions} />
        <Route
          path={routes.suggestionsWithPageNum}
          exact
          component={Suggestions}
        />
        <Route path={routes.targeting} exact component={TargetV2} />
        <Route path={routes.targetingDetails} exact component={TargetV2} />
        {/* <Route path={routes.newHome} exact component={Home} /> */}

        <Route path={routes.shipment} exact component={Shipment} />
        <Route path={routes.shipmentWithFiltersId} exact component={Shipment} />
        {/* <Route path={routes.targetFilter} exact component={TargetFilter} />
        <Route path={routes.targetDetails} exact component={TargetFilter} /> */}
        {/* <Route path={routes.simulator} exact component={Simulate} />
        <Route
          path={routes.simulatorWithFiltersId}
          exact
          component={Simulate}
        /> */}
        <Route path={routes.targetingScreens} exact component={Target} />
        <Route
          path={routes.sampleComponents}
          exact
          component={SsampleComponents}
        />
        <Route path={routes.dashboard} exact component={Dashboard} />
        <Route path={routes.columnChart} exact component={ColumnChart} />
        {/* <Route path={routes.impact} exact component={Impact} />
        <Route path={routes.impactWithFiltersId} exact component={Impact} /> */}
        <Route path={routes.privacy} exact component={Privacy} />
        {/* <Route path={routes.apps} exact component={Apps} /> */}
        <Route path={routes.appsDetails} exact component={AppsDetails} />
        <Route path={routes.targetPage} exact component={TargetV2} /> 
        {/* <Route path={routes.targetPage} exact>
          <TargetV2 isIframe={true} />
        </Route> */}
        <Route path={routes.appsInfo} exact component={AppsInfo} />
        <Route path={routes.targetAppDashboard} exact>
          <Dashboard></Dashboard>
        </Route>
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
      </Switch>
    </React.Suspense>
  );
}

export default Routes;
