import {
  FilterList,
  GroupType,
  OperatorType,
  SubGroupFilterItem,
} from "../types/targetV2.types";

export const initialFiltersData: FilterList[] = [
  {
    id: 1,
    name: "Profile",
    filterItemsChildren: [
      {
        id: 12,
        filterId: 12,
        name: "Salesforce",
        filters: ["Salesforce_1", "Salesforce_2", "Salesforce_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 13,
        filterId: 13,
        name: "Business_unit",
        filters: ["Business_unit_1", "Business_unit_2", "Business_unit_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 14,
        filterId: 14,
        name: "Region",
        filters: ["Region_1", "Region_2", "Region_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 15,
        filterId: 15,
        name: "Network",
        filters: ["Network_1", "Network_2", "Network_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 16,
        filterId: 16,
        name: "State",
        filters: ["State_1", "State_2", "State_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 17,
        filterId: 17,
        name: "Brand",
        filters: ["Xarelto", "Eylea", "Brand 3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 18,
        filterId: 18,
        name: "Indication",
        filters: ["Indication 1", "Indication 2", "Indication 3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 19,
        filterId: 19,
        name: "Segment",
        filters: ["A", "B", "C", "D"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 191,
        filterId: 191,
        name: "Speciality",
        filters: [
          "Urology",
          "Hematology",
          "Cardiology",
          "Generalist",
          "Internal Medicine",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 192,
        filterId: 192,
        name: "Consent",
        filters: [],
        filtersSelected: [],
        isResponsive: false,
        groupFilter: {
          id: 123,
          subGroupId: 123,
          name: "Sub Group",
          operator: OperatorType.AND,
          groupType: GroupType.CLASSIC,
          parentId: 192,
          filterItemsChildren: [
            {
              id: 1921,
              filterId: 1921,
              name: "Consent Type",
              filters: ["AE", "BE", "CE"],
              filtersSelected: [],
              isResponsive: false,
            },
            {
              id: 1922,
              filterId: 1922,
              name: "Consent Status",
              filters: [
                "Consent_Status_1",
                "Consent_Status_2",
                "Consent_Status_3",
              ],
              filtersSelected: [],
              isResponsive: false,
            },
          ],
          subgGroupFilterItemsChildren: [],
        },
      },

      {
        id: 194,
        filterId: 194,
        name: "Therapeutic Area",
        filters: ["Ophtalmology", "Neurogoly", "Dermatology"],
        filtersSelected: [],
        isResponsive: false,
      },

      {
        id: 195,
        filterId: 195,
        name: "My Target",
        filters: ["true", "false"],
        filtersSelected: [],
        isResponsive: false,
      },

      {
        id: 196,
        filterId: 196,
        name: "Microsegment",
        filters: ["MA", "MB", "MC", "MD"],
        filtersSelected: [],
        isResponsive: false,
      },
    ],
  },
  {
    id: 2,
    name: "Demographic",
    filterItemsChildren: [
      {
        id: 21,
        filterId: 21,
        name: "Potential",
        filters: ["Potential_1", "Potential_2", "Potential_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 22,
        filterId: 22,
        name: "Secondary Speciality",
        filters: [
          "Secondary_Speciality_1",
          "Secondary_Speciality_2",
          "Secondary_Speciality_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 23,
        filterId: 23,
        name: "Practice Type",
        filters: ["Practice_Type_1", "Practice_Type_2", "Practice_Type_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 24,
        filterId: 24,
        name: "Gender",
        filters: ["Gender_1", "Gender_2", "Gender_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 25,
        filterId: 25,
        name: "Work Place",
        filters: ["Work_Place_1", "Work_Place_2", "Work_Place_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 26,
        filterId: 26,
        name: "Thought Leader",
        filters: ["Thought Leader_1", "Thought Leader_2", "Thought Leader_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 27,
        filterId: 27,
        name: "Professional title",
        filters: [
          "Professional_Title_1",
          "Professional_Title_2",
          "Professional_Title_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 28,
        filterId: 28,
        name: "Person type",
        filters: ["Person_type_1", "Person_type_2", "Person_type_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 29,
        filterId: 29,
        name: "Special qualification",
        filters: [
          "Special_Qualification_1",
          "Special_Qualification_2",
          "Special_Qualification_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 291,
        filterId: 291,
        name: "Academic Title",
        filters: ["Academic_Title_1", "Academic_Title_2", "Academic_Title_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 292,
        filterId: 292,
        name: "Account language",
        filters: [
          "Account_Language_1",
          "Account_Language_2",
          "Account_Language_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 293,
        filterId: 293,
        name: "MCCP",
        filters: [],
        filtersSelected: [],
        isResponsive: false,
        groupFilter: {
          id: 223789,
          subGroupId: 223789,
          name: "MCCP",
          operator: OperatorType.AND,
          groupType: GroupType.MCCP,
          parentId: 293,
          filterItemsChildren: [
            {
              id: 298939889,
              filterId: 298939889,
              name: "Date Cycle",
              filters: ["10/12/2023", "20/05/2012", "23/04/2024"],
              filtersSelected: [],
              isResponsive: false,
            },
            {
              id: 298932,
              filterId: 298932,
              name: "Channel",
              filters: ["Approved Email", "Newsletter"],
              filtersSelected: [],
              isResponsive: false,
            },
            {
              id: 29839,
              filterId: 29839,
              name: "Brand",
              filters: ["Brand_1", "Brand_2", "Brand_3"],
              filtersSelected: [],
              isResponsive: false,
            },
            {
              id: 29890,
              filterId: 29890,
              name: "MCCP",
              filters: ["Target", "Target_2", "Target_3"],
              filtersSelected: [],
              isResponsive: false,
            },
          ],
          subgGroupFilterItemsChildren: [],
        },
      },
    ],
  },
  {
    id: 3,
    name: "Behavior",
    filterItemsChildren: [
      {
        id: 31,
        filterId: 31,
        name: "Prescription Behaviour",
        filters: [
          "Prescription_Behaviour_1",
          "Prescription_Behaviour_2",
          "Prescription_Behaviour_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 32,
        filterId: 3,
        name: "Digital Behaviour",
        filters: [
          "Digital_Behaviour_1",
          "Digital_Behaviour_2",
          "Digital_Behaviour_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 33,
        filterId: 33,
        name: "Adoption Ladder",
        filters: [
          "Adoption_Ladder_1",
          "Adoption_Ladder_2",
          "Adoption_Ladder_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 34,
        filterId: 34,
        name: "Content Preference",
        filters: [
          "Content_Preference_1",
          "Content_Preference_2",
          "Content_Preference_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 35,
        filterId: 35,
        name: "Channel Preference",
        filters: [
          "Channel_Preference_1",
          "Channel_Preference_2",
          "Channel_Preference_3",
        ],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 36,
        filterId: 36,
        name: "Payer",
        filters: ["Payer_1", "Payer_2", "Payer_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 37,
        filterId: 37,
        name: "Protection",
        filters: ["Protection_1", "Protection_2", "Protection_3"],
        filtersSelected: [],
        isResponsive: false,
      },
      {
        id: 38,
        filterId: 38,
        name: "Tendency",
        filters: ["Tendency_1", "Tendency_2", "Tendency_3"],
        filtersSelected: [],
        isResponsive: false,
      },
    ],
  },
  {
    id: 4,
    name: "Past Interaction",
    filterItemsChildren: [
      {
        id: 4022,
        filterId: 4022,
        name: "Approved email",
        filters: [],
        filtersSelected: [],
        isResponsive: false,
        groupFilter: {
          id: 42178,
          subGroupId: 42178,
          name: "Approved email",
          operator: OperatorType.AND,
          parentId: 4022,
          groupType: GroupType.PAST_INTERACTION,
          filterItemsChildren: [
            {
              id: 41123782,
              filterId: 41123782,
              name: "Date range",
              filters: [],
              filtersSelected: [],
              isResponsive: false,
            },
          ],
          subgGroupFilterItemsChildren: [
            {
              id: 412337,
              subGroupId: 412337,
              name: "Approved email groupp",
              parentId: 42178,
              operator: OperatorType.AND,
              groupType: GroupType.PAST_INTERACTION,
              filterItemsChildren: [
                {
                  id: 41212,
                  filterId: 41212,
                  name: "Status (AE) delivery",
                  filters: ["Status_1", "Status_2", "Status_3"],
                  filtersSelected: [],
                  isResponsive: false,
                },
                {
                  id: 4218890,
                  filterId: 4218890,
                  name: "Status reaction",
                  filters: [
                    "Status_Reaction_1",
                    "Status_Reaction_2",
                    "Status_Reaction_3",
                  ],
                  filtersSelected: [],
                  isMandatory: false,
                  isResponsive: false,
                },
              ],
              subgGroupFilterItemsChildren: [],
            },
          ],
        },
      },
      {
        id: 4098782,
        filterId: 4098782,
        name: "Newsletter",
        filters: [],
        filtersSelected: [],
        isResponsive: false,
        groupFilter: {
          id: 4356728,
          subGroupId: 4356728,
          name: "Newsletter",
          operator: OperatorType.AND,
          parentId: 4098782,
          groupType: GroupType.PAST_INTERACTION,
          filterItemsChildren: [
            {
              id: 5552626,
              filterId: 5552626,
              name: "Date range",
              filters: [],
              filtersSelected: [],
              isResponsive: false,
            },
          ],
          subgGroupFilterItemsChildren: [
            {
              id: 435234,
              subGroupId: 435234,
              name: "Newsletter",
              parentId: 4356728,
              operator: OperatorType.AND,
              groupType: GroupType.PAST_INTERACTION,
              filterItemsChildren: [
                {
                  id: 419382,
                  filterId: 419382,
                  name: "Journey ID",
                  filters: ["JourneyID_1", "JourneyID_2", "JourneyID_3"],
                  filtersSelected: [],
                  isResponsive: false,
                },
                {
                  id: 837637,
                  filterId: 837637,
                  name: "Therapeutical area",
                  filters: [
                    "Therapeutical_area_1",
                    "Therapeutical_area_2",
                    "Therapeutical_area_3",
                  ],
                  filtersSelected: [],
                  isMandatory: false,
                  isResponsive: false,
                },
                {
                  id: 36672,
                  filterId: 36672,
                  name: "Status (received, click...)",
                  filters: ["Received", "Click"],
                  filtersSelected: [],
                  isMandatory: true,
                  isResponsive: false,
                },
                {
                  id: 398728,
                  filterId: 398728,
                  name: "Activity name",
                  filters: ["Activity_1", "Activity_2"],
                  filtersSelected: [],
                  isMandatory: true,
                  isResponsive: false,
                },
              ],
              subgGroupFilterItemsChildren: [],
            },
          ],
        },
      },

      {
        id: 41,
        filterId: 41,
        name: "Events",
        filters: [],
        filtersSelected: [],
        isResponsive: false,
        groupFilter: {
          id: 421,
          subGroupId: 421,
          name: "Events",
          operator: OperatorType.AND,
          parentId: 41,
          groupType: GroupType.PAST_INTERACTION,
          filterItemsChildren: [
            {
              id: 4112,
              filterId: 4112,
              name: "Date range",
              filters: [],
              filtersSelected: [],
              isResponsive: false,
            },
          ],
          subgGroupFilterItemsChildren: [
            {
              id: 4127,
              subGroupId: 4127,
              name: "Events sub groupp",
              parentId: 421,
              operator: OperatorType.AND,
              groupType: GroupType.PAST_INTERACTION,
              filterItemsChildren: [
                {
                  id: 41212,
                  filterId: 41212,
                  name: "State",
                  filters: ["France", "UK", "Russia"],
                  filtersSelected: [],
                  isResponsive: false,
                },
                {
                  id: 4213,
                  filterId: 4123,
                  name: "City",
                  filters: ["Paris", "London", "Berlin"],
                  filtersSelected: [],
                  isMandatory: false,
                  isResponsive: false,
                },
                {
                  id: 4214,
                  filterId: 4124,
                  name: "Event scope",
                  filters: ["Event_scope_1", "Event_scope_2", "Event_scope_3"],
                  filtersSelected: [],
                  isMandatory: false,
                  isResponsive: false,
                },
                {
                  id: 4215,
                  filterId: 4125,
                  name: "Event status",
                  filters: [
                    "Event_status_1",
                    "Event_status_2",
                    "Event_status_3",
                  ],
                  filtersSelected: [],
                  isMandatory: true,
                  isResponsive: false,
                },
                {
                  id: 4216,
                  filterId: 4126,
                  name: "Event sub-type",
                  filters: [
                    "Event_sub-type_1",
                    "Event_sub-type_2",
                    "Event_sub-type_3",
                  ],
                  filtersSelected: [],
                  isMandatory: false,
                  isResponsive: false,
                },
                {
                  id: 4217,
                  filterId: 4127,
                  name: "Event name",
                  filters: ["Event_name_1", "Event_name_2", "Event_name_3"],
                  filtersSelected: [],
                  isMandatory: true,
                  isResponsive: false,
                },
                {
                  id: 4218,
                  filterId: 4128,
                  name: "Event mode",
                  filters: ["Event_mode_1", "Event_mode_2", "Event_mode_3"],
                  filtersSelected: [],
                  isMandatory: false,
                  isResponsive: false,
                },
                {
                  id: 4219,
                  filterId: 4129,
                  name: "Event type",
                  filters: ["Event_type_1", "Event_type_2", "Event_type_3"],
                  filtersSelected: [],
                  isMandatory: false,
                  isResponsive: false,
                },
                {
                  id: 4220,
                  filterId: 4120,
                  name: "Status (attended, invited)",
                  filters: ["Attended", "Invited"],
                  filtersSelected: [],
                  isMandatory: true,
                  isResponsive: false,
                },
              ],
              subgGroupFilterItemsChildren: [],
            },
          ],
        },
      },
      {
        id: 40832212,
        filterId: 40832212,
        name: "Survey",
        filters: [],
        filtersSelected: [],
        isResponsive: true,
        groupFilter: {
          id: 400986,
          subGroupId: 400986,
          name: "Survey",
          operator: OperatorType.AND,
          parentId: 40832212,
          groupType: GroupType.SURVEY,
          filterItemsChildren: [
            {
              id: 476827,
              filterId: 476827,
              name: "Date range",
              filters: [],
              filtersSelected: [],
              isResponsive: true,
            },
            {
              id: 436525,
              filterId: 436525,
              name: "Survey name",
              filters: [],
              filtersSelected: [],
              isResponsive: true,
            },
          ],
          subgGroupFilterItemsChildren: [],
        },
      },
    ],
  },
];

export const initialQueryContainerDataEmpty: Array<SubGroupFilterItem> = [
  {
    id: 12345,
    subGroupId: 12345,
    name: "Start selecting your filters",
    operator: OperatorType.AND,
    parentId: null,
    groupType: GroupType.CLASSIC,
    filterItemsChildren: [
      {
        id: 123,
        filterId: 123,
        name: "Country",
        filters: ["NO","DE","FI","RU","BE","CH","DK","IT","FR","ES","SE","GB","NL","TR","CA"],
        filtersSelected: [],
        isMandatory: true,
        isResponsive: true,
      },
    ],
    subgGroupFilterItemsChildren: [],
  },
];
