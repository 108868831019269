import { MenuItem } from "models/menu";

/**
 * All the menu items are configured here
 * @returns array
 */
const getMenu = (isTarget: any): MenuItem[] => {
  if(isTarget){
    return[
       {
      id: 3,
      label: "dashboard",
      iconClass: "icon-menu-dashboard",
      path: "appsInfo/2",
      inUse: true,
      titleLabel: "dashboard",
      hasSubmenu: true,
      page:"dashboard",
      subMenu: [
        {
          id: 5,
          label: "dashboard",
          path: "appsInfo/2",
          inUse: true,
          titleLabel: "dashboard",
          page:"targetAppDashboard",
        },
        {
          id: 6,
          label: "target_list_generator_menu_label",
          path: "appsInfo/2",
          inUse: true,
          titleLabel: "target_list_generator_menu_label",
          page:"targetPage"
        }     
      ],
    }]

  }else{
  return [
    // {
    //   id: 1,
    //   label: "home",
    //   iconClass: "icon-menu-home",
    //   path: "home",
    //   inUse: true,
    //   titleLabel: "home",
    // },
    // {
    //   id: 2,
    //   label: "dashboard",
    //   iconClass: "icon-menu-dashboard",
    //   path: "dashboard",
    //   inUse: true,
    //   titleLabel: "dashboard",
    // },
    {
      id: 3,
      label: "target_list_generator_menu_label",
      iconClass: "icon-menu-campaign",
      path: "targeting",
      inUse: false,
      titleLabel: "target_list_generator_menu_label",
      hasSubmenu: true,
      subMenu: [
        // {
        //   id: 5,
        //   label: "suggestion_menu_label",
        //   path: "suggestions",
        //   inUse: true,
        //   titleLabel: "suggestions",
        // },
        // {
        //   id: 6,
        //   label: "target_menu_label",
        //   path: "targeting",
        //   inUse: true,
        //   titleLabel: "campaign_targeting",
        // },
        // {
        //   id: 7,
        //   label: "simulate_menu_label",
        //   path: "simulate",
        //   inUse: true,
        //   titleLabel: "campaign_logistics_simulator",
        // },  
        {
          id: 8,
          label: "target_list_generator_menu_label",
          path: "targeting",
          inUse: true,
          titleLabel: "target_list_generator_menu_label",
        },
        {
          id: 9,
          label: "dashboard",
          path: "dashboard",
          inUse: true,
          titleLabel: "dashboard",
        },      
      ],
    },
    // {
    //   id: 4,
    //   label: "apps",
    //   iconClass: "icon-menu-apps",
    //   path: "apps",
    //   inUse: true,
    //   titleLabel: "apps",
    // },
  ];
}
};

export { getMenu };
