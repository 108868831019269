import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import "./FilterRow.scss";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import { FilterItem } from "pages/TargetV2/types/targetV2.types";
import SelectSimpleFilter from "./SelectSimpleFilter";
import "./SimpleFilterRow.scss";
import { useTranslation } from "react-i18next";
import DropDownSelectFilter from "./dropdownSelectFilter/DropDownSelectFilter";

type SimpleFilterRowProps = {
  filterItem: FilterItem;
  id: number;
  index: number;
  width?: string;
  style?: React.CSSProperties | undefined;
  borderColor?: string;
  halfWidth?: boolean;
};

const SimpleFilterRow: FC<SimpleFilterRowProps> = ({
  filterItem,
  id,
  index,
  width,
  style,
  borderColor,
  halfWidth,
}) => {
  const [isExpandFilter, setIsExpandFilter] = useState<boolean>(false);

  const { handleUpdateSimpleFilterSelected, isMissingMandatoryFields } =
    useTargetV2Context();

  const refDropdown = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsExpandFilter(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandFilter]);

  const handleExpandFilter = useCallback(() => {
    setIsExpandFilter((state) => !state);
  }, []);

  const handleUpdateSingleFilter = useCallback(
    (filter: string) => {
      handleUpdateSimpleFilterSelected(
        id,
        index,
        filter,
        filterItem.isResponsive
      );
      setIsExpandFilter(false);
    },
    [id, index, handleUpdateSimpleFilterSelected, setIsExpandFilter, filterItem]
  );

  return (
    <Box
      className={`simple-filter-row-container ${
        halfWidth ? "half-width-container" : ""
      }`}
    >
      <Box className="simple-filter-list-wrapper">
        <Box
          width={width ?? "369px"}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          gap="10px"
          style={style}
        >
          <Box display="flex" justifyContent="space-between">
            <Box className="simple-filter-name">{filterItem.name} </Box>
            {filterItem.isMandatory && (
              <Box className="mandatory"> {t("mandatory")} </Box>
            )}
          </Box>

          <Box
            style={{
              position: "relative",
            }}
            ref={refDropdown}
          >
            <SelectSimpleFilter
              handleExpandFilter={handleExpandFilter}
              filtersSelected={filterItem.filtersSelected}
              isExpandFilter={isExpandFilter}
              filter={filterItem.name}
              borderColor={borderColor}
            />
            {filterItem.isMandatory &&
              filterItem.filtersSelected.length < 1 &&
              isMissingMandatoryFields && (
                <Box className="mandatory-missing">
                  The {filterItem.name} need to be selected
                </Box>
              )}

            {isExpandFilter && (
              <DropDownSelectFilter
                isMulti={filterItem.multiSelect}
                handleUpdateFilter={handleUpdateSingleFilter}
                filters={filterItem.filters}
                filtersSelected={filterItem.filtersSelected}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SimpleFilterRow;
