import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import "../FilterRow.scss";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import { FilterItem } from "pages/TargetV2/types/targetV2.types";
import SelectSimpleFilter from "../SelectSimpleFilter";
import "./SurveyFilterRow.scss";
import MultiSimpleSelectFilter from "../MultiSimpleSelectFilter";
import DropDownSelectFilter from "../dropdownSelectFilter/DropDownSelectFilter";

type SurveyFilterRowProps = {
  filterItem: FilterItem;
  id: number;
  index: number;
  width?: string;
  style?: React.CSSProperties | undefined;
  isQuestion?: boolean;
};

const SurveyFilterRow: FC<SurveyFilterRowProps> = ({
  filterItem,
  id,
  index,
  width,
  style,
  isQuestion,
}) => {
  const [isExpandFilter, setIsExpandFilter] = useState<boolean>(false);

  const { handleUpdateSimpleFilterSelected, handleUpdateMultiFilterSelected } =
    useTargetV2Context();

  const refDropDownSurvey = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        refDropDownSurvey.current &&
        !refDropDownSurvey.current.contains(e.target)
      ) {
        setIsExpandFilter(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandFilter]);

  const handleExpandFilter = useCallback(() => {
    setIsExpandFilter((state) => !state);
  }, []);

  const handleUpdateFilterClick = useCallback(
    (filter: string) => {
      if (filterItem.multiSelect) {
        let action: "Add" | "Remove" = "Add";

        if (
          filterItem.filtersSelected.some(
            (filterSelected) => filterSelected === filter
          )
        ) {
          action = "Remove";
        }

        handleUpdateMultiFilterSelected(id, index, action, filter);
      } else {
        handleUpdateSimpleFilterSelected(
          id,
          index,
          filter,
          filterItem.isResponsive
        );
        setIsExpandFilter(false);
      }
    },
    [
      id,
      index,
      handleUpdateSimpleFilterSelected,
      setIsExpandFilter,
      filterItem,
      handleUpdateMultiFilterSelected,
    ]
  );

  return (
    <Box display="flex">
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        position="relative"
        style={style}
      >
        <Box
          className={
            isQuestion ? "survey-filter-question" : "survey-filter-name"
          }
          style={{ minWidth: "120px" }}
        >
          {filterItem.name}
        </Box>

        <Box
          style={{
            position: "relative",
            width: width ?? "100%",
          }}
          ref={refDropDownSurvey}
        >
          {filterItem.multiSelect ? (
            <MultiSimpleSelectFilter
              handleExpandFilter={handleExpandFilter}
              filtersSelected={filterItem.filtersSelected}
              isExpandFilter={isExpandFilter}
              filter={filterItem.name}
              operator={filterItem.operatorBetweenFiltersSelected}
              isQuestion={isQuestion}
            />
          ) : (
            <SelectSimpleFilter
              handleExpandFilter={handleExpandFilter}
              filtersSelected={filterItem.filtersSelected}
              isExpandFilter={isExpandFilter}
              filter={filterItem.name}
              borderColor="#DFE2E5"
              width="100%"
              isQuestion={isQuestion}
            />
          )}

          {isExpandFilter && (
            <DropDownSelectFilter
              isMulti={filterItem.multiSelect}
              handleUpdateFilter={handleUpdateFilterClick}
              filters={filterItem.filters}
              filtersSelected={filterItem.filtersSelected}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyFilterRow;
