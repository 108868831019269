import React, { useContext, useEffect } from "react";
import { FormContext } from "contexts/FormContext";
import "./CheckBoxNormal.scss";

//set useFormContext in props to true to invoke handleChange function
export function CheckBoxNormal(props: any) {
  const [value, setValue] = React.useState(props.checked);
  const formContext = useContext(FormContext);
  useEffect(() => {
    setValue(props.checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checked]);
  const invokeHandleChange = (value: any) => {
    const { handleChange } = formContext;
    if (props.useFormContext) {
      handleChange(value, props);
    }
  };
  const onCheck = (event: any) => {
    setValue(!value);
    if (props.useFormContext) {
      invokeHandleChange(!value);
    } else if (props.onChange) props.onChange(!value);
  };
  return (
    <span
      className={`checkbox-container ${props.className ? props.className : ""}`}
    >
      <input type="checkbox" checked={value} readOnly />
      <span
        className={`checkmark ${props.className ? "" : ""}`}
        onClick={onCheck}
      ></span>
    </span>
  );
}
