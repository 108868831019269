import { Box } from "@mui/material";
import React, { FC } from "react";
import "./SelectFilter.scss";
import dropdown_arrow_black from "../../../../../../../src/assets/icons/icon-dropdown-arrow-black.svg";
import dropdown_arrow_blue from "../../../../../../../src/assets/icons/icon-arrow-down-blue.svg";
import "./SelectSimpleFilter.scss";

type SelectSimpleFilterProps = {
  handleExpandFilter: () => void;
  filtersSelected?: Array<string>;
  isExpandFilter: boolean;
  filter: string;
  borderColor?: string;
  width?: string;
  isQuestion?: boolean;
};

const SelectSimpleFilter: FC<SelectSimpleFilterProps> = ({
  handleExpandFilter,
  filtersSelected,
  isExpandFilter,
  filter,
  borderColor,
  width,
  isQuestion,
}) => {
  console.log(filtersSelected);
  return (
    <Box
      className="simple-filter-select-wrapper"
      style={{
        borderColor: isExpandFilter
          ? "#2D9DE2"
          : borderColor
          ? borderColor
          : "",
        width: width ?? "",
      }}
      onClick={handleExpandFilter}
    >
      <Box className="select-filter-wrapper">
        <Box className="filter-selected-wrapper">
          <Box className="simple-filter-selected-name">
            {filtersSelected && filtersSelected?.length > 0
              ? filtersSelected[0]
              : isQuestion
              ? "Select the answer"
              : `Select the ${filter}`}
          </Box>
        </Box>
      </Box>
      {/* <Box className="border-chevron-wrapper">
        <img
          alt="dropdown"
          src={isExpandFilter ? dropdown_arrow_blue : dropdown_arrow_black}
          style={{
            transform: isExpandFilter ? "rotate(-180deg)" : "",
            paddingRight: isExpandFilter ? "" : "12px",
            paddingLeft: isExpandFilter ? "12px" : "",
            height: "24px",
            width: "24px",
          }}
        />
      </Box> */}
      <Box className="border-chevron-wrapper">
        <img
          src={dropdown_arrow_blue}
          alt="Gray icon"
          className="arrow-down"
          style={{ transform: isExpandFilter ? "rotate(180deg)" : "" }}
        />
      </Box>
    </Box>
  );
};

export default SelectSimpleFilter;
