import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./CustomToast.scss";
import { customToastInfoState, notificationState } from "recoil/atoms";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
//import read_icon from "../../../../src/assets/icons/notification-read.svg";

export const CustomToast = (props: any) => {
  const { toastList, position } = props;
  const [list, setList] = useState(toastList);

  const notificationConfig = useRecoilValue(notificationState);
  const setCustomConfig = useResetRecoilState(customToastInfoState);

  useEffect(() => {
    setList(toastList);
  }, [toastList]);

  useEffect(() => {
    let res = [
      ...list,
      {
        id: 3,
        headingMessage:
          notificationConfig &&
          notificationConfig.iconClass === "icon-errortick"
            ? notificationConfig.message
            : "Success",
        // headingMessage: notificationConfig.message,
        message:notificationConfig.data ? notificationConfig.data.errorDetail  ? notificationConfig.data.errorDetail.message:notificationConfig.message:"",
        marginColor: notificationConfig.marginColor,
        contentColor: notificationConfig.contentColor,
        iconClass: notificationConfig.iconClass,
        messageClass: "message-class",
      },
    ];

    if (notificationConfig && notificationConfig.message) {
      setList(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationConfig]);

  const deleteToast = (id: any) => {
    const listItemIndex = list.findIndex((e: any) => e.id === id);
    let newList = [...list];
    if (listItemIndex > -1) {
      newList.splice(parseInt(listItemIndex), 1);
    }
    setList([...newList]);
  };
  return (
    <>
      <div className={`notification-container-new ${position}`}>
        {list &&
          list.length > 0 &&
          list.map((toast: any, i: any) => (
            <div
              key={i}
              className={`notification-new toast-new ${position}
            ${toast.className ? toast.className : ""} ${
                toast.marginColor ? toast.marginColor : ""
              } ${toast.contentColor ? toast.contentColor : ""}`}
            >
              <button onClick={() => deleteToast(parseInt(toast.id))}>X</button>

              <Box
                className={`notification-image ${toast.iconClass} ${
                  props.readStatus ? "icon-read" : ""
                }`}
              ></Box>

                <Grid item xs={12}>
                  <Box className="notification-content">
                    {toast.headingMessage ? (
                      <Box className="text-bold-new">{toast.headingMessage}</Box>
                    ) : (
                      ""
                    )}
                    <Box
                      className={` ${
                        toast.messageClass ? toast.messageClass : ""
                      } message-name`}
                    >
                      <Box className="message-first-class">
                        {toast.message}                       
                      </Box>
                      <Grid item xs={11.7}>
                        {props.showViewButton && (
                          <Box className="message-second-class">
                            <Box className="notification-message">{toast.message}</Box>                       
                          </Box>
                        )}
                      </Grid>                    
                    </Box>
                  </Box>{" "}
                </Grid>         
            </div>
          ))}
      </div>
    </>
  );
};
