import { Box } from "@mui/material";
import React, { FC } from "react";
import "./SelectFilter.scss";
import dropdown_arrow_black from "../../../../../../../src/assets/icons/icon-dropdown-arrow-black.svg";
import dropdown_arrow_blue from "../../../../../../../src/assets/icons/icon-arrow-down-blue.svg";
import "./MultiSimpleSelectFilter.scss";
import { OperatorType } from "pages/TargetV2/types/targetV2.types";

type MultiSimpleSelectFilterProps = {
  handleExpandFilter: () => void;
  filtersSelected?: Array<string>;
  isExpandFilter: boolean;
  filter: string;
  operator?: OperatorType | null;
  isQuestion?: boolean;
};

const MultiSimpleSelectFilter: FC<MultiSimpleSelectFilterProps> = ({
  handleExpandFilter,
  filtersSelected,
  isExpandFilter,
  filter,
  operator,
  isQuestion,
}) => {
  console.log(filtersSelected);
  return (
    <Box
      className="multi-simple-filter-select-wrapper"
      style={{ borderColor: isExpandFilter ? "#2D9DE2" : "" }}
      onClick={handleExpandFilter}
    >
      <Box className="select-filter-wrapper" width="90%">
        <Box className="filter-selected-wrapper">
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent={
              filtersSelected && filtersSelected.length >= 2
                ? "center"
                : "flex-start"
            }
          >
            {filtersSelected && filtersSelected?.length > 0 ? (
              <>
                {filtersSelected.map((filterSelected, i) => (
                  <Box
                    key={`${filterSelected}-${i}`}
                    className="simple-filter-multi-selected-name"
                    display="flex"
                    gap="10px"
                    alignItems="center"
                  >
                    {i <= 1 && filterSelected}
                    {i < filtersSelected.length - 1 && (
                      <Box className="operator-between-filters">
                        {operator ?? "And"}
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            ) : isQuestion ? (
              <Box className="simple-filter-multi-selected-name">
                Select the answer
              </Box>
            ) : (
              <Box className="simple-filter-multi-selected-name">
                Select the ${filter}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {filtersSelected && filtersSelected.length > 2 && (
        <Box className="more-filters-selected" minWidth="50px">
          {filtersSelected.length - 2} more
        </Box>
      )}
      <Box className="border-chevron-wrapper">
        <img
          alt="dropdown"
          src={isExpandFilter ? dropdown_arrow_blue : dropdown_arrow_black}
          style={{
            transform: isExpandFilter ? "rotate(-180deg)" : "",
            paddingRight: isExpandFilter ? "" : "12px",
            paddingLeft: isExpandFilter ? "12px" : "",
            height: "24px",
            width: "24px",
          }}
        />
      </Box>
    </Box>
  );
};

export default MultiSimpleSelectFilter;
