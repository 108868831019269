import React, { Suspense } from "react";
import TargetV2Provider from "./context/targetV2.context";
import "./TargetV2.scss";
import TargetV2Container from "./components/TargetV2Container";

const TargetV2 = (props: any) => {
  const targetID =
    props && props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : null;
  return (
    <TargetV2Provider>
      {() => (
        <Suspense fallback={"...Loading"}>
          <TargetV2Container
            savedTargetID={targetID}
          />
        </Suspense>
      )}
    </TargetV2Provider>
  );
};

export default TargetV2;
