import React, { useEffect, useState, useContext, useRef } from "react";
import { useRecoilState } from "recoil";
import { Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getData } from "helpers/formHelper";
import {
  DropdownConfig,
  OptionItem,
  ParamseterItem,
  ValidatorItem,
} from "models/dropdown";
import { ValidationObject } from "models/form";
import { FormContext } from "contexts/FormContext";
import { sharedFormElementState } from "recoil/atoms";
import dropdown_arrow_black from "../../../../src/assets/icons/icon-dropdown-arrow-black.svg";
import dropdown_arrow_blue from "../../../../src/assets/icons/icon-arrow-down-blue.svg";

/* Dropdown Component */
export function Dropdown({
  config,
  formValues,
  onChange,
}: // formStateAtom,
{
  config: DropdownConfig;
  formValues?: any;
  onChange?: any;
  // formStateAtom?: RecoilState<any>;
}) {  
  const refDropdown = useRef<any>();
  const [options, setOptions] = useState<OptionItem[]>([]);
  const [value, setValue] = useState<any>();
  const [label, setLabel] = useState<any>();
  const [dropDownPosition, setDropDownPosition] = useState<any>();

  const [validationObj, setValidationObj] = useState<ValidationObject>({
    error: false,
    message: "",
  });
  const formContext = useContext(FormContext);
  const [formElement, setFormElement] = useRecoilState(sharedFormElementState);
  // const formValues = useRecoilValue(formStateAtom);
  const [elementConfig, setElementConfig] = useState<DropdownConfig>(config);
  const { t } = useTranslation();
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  /**
   * Validate form field
   */
  const validate = (value: any) => {
    if (
      elementConfig.validators &&
      Array.isArray(elementConfig.validators) &&
      elementConfig.validators.length
    ) {
      let validationObj: ValidationObject = { error: false, message: "" };
      elementConfig.validators.every((validator: ValidatorItem) => {
        const params = [value];
        // for validators like minLength & maxLength, we need additional values like number of characters to be validated
        validator.validationvalue && params.push(validator.validationvalue);
        const valid = validator.validatorFunction(...params);
        if (!valid) {
          validationObj = { error: true, message: validator.message };
        }
        // stop iteration at 1st validation failure
        return valid;
      });
      setValidationObj(validationObj);
    }
  };
  const alignDropdown = (event: any) => {
    let windowHeight = window.innerHeight;
    let positionY = event.pageY;
    let spaceDown = windowHeight - positionY;
    let dropDownPos = spaceDown > 282 ? "" : "up";
    setDropDownPosition(dropDownPos);
  };
  useEffect(() => {
    setElementConfig(config);
    // load initial data
    getData(config, formValues)?.then((options: OptionItem[]) => {
      setOptions(options);
    });
    if (config.value) {
      valueChanged(config.value); // prefill input with the default value provided with config
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if (config.clearValue) {
      setValue([]);
      valueChanged("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.clearValue]);

  useEffect(() => {
    /** invoked on one of the dropdown is changed and the data for
     * this element depends on the value of the changed element */
    if (formElement && formElement.id && formElement.id === elementConfig.id) {
      const updatedConfig: DropdownConfig = {
        ...elementConfig,
        ...{ fetch: formElement },
      };
      setElementConfig(updatedConfig);
      setOptions([]); // empty options before fetching new values
      let fetchDataFlag = true;
      if (
        formElement &&
        formElement.params &&
        Array.isArray(formElement.params) &&
        formElement.params.length
      ) {
        // check for existence of parameters and their values.
        formElement.params.forEach((param: ParamseterItem) => {
          const paramvalue = param.value
            ? param.value
            : param.valueReference // valueReference indicates one of the fields in the same form state
            ? formValues[param.valueReference]
            : "";
          fetchDataFlag =
            paramvalue && paramvalue !== "" ? fetchDataFlag : false;
        });
      }
      if (fetchDataFlag) {
        // Fetch new options only if the related field has a value.
        // Eg. States should load value only if contry has a value
        // updatedConfig is passed instead of elementConfig because of the asynchronous nature of useState and possibility of it not getting updated by this time
        getData(updatedConfig, formValues)?.then((options: OptionItem[]) => {
          setOptions(options);
          if (updatedConfig.value) {
            //really need to check if this is necessary
            valueChanged(updatedConfig.value); // prefill input with the default value provided with config
          }
        });
      }
      setFormElement(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formElement]);

  /**
   * Update form element value if the state is a different one
   * if a form state is modified, it should reflect in the form element.
   * This makes the form completely controllable from scripts
   */
  useEffect(() => {
    if (
      config.useFormContext &&
      formValues[config.id] &&
      value !== formValues[config.id]
    ) {
      setValue(formValues[config.id].value);
      setLabel(formValues[config.id].label);
    }
    else if (
      config.useFormContext &&
      config.id in formValues &&
      !formValues[config.id]
    ) {
      setValue(null);
      setLabel(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    if (config.forceContext && config.forceContextValues) {
      setValue(config.forceContextValues.value);
      setLabel(config.forceContextValues.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config,config.forceContext]);

  /**
   * Propagate the selected values to the parent
   * @param value
   */
  const valueChanged = (value: any) => {
    setValue(value.value);
    setLabel(value.label);

    if (elementConfig.fetch && elementConfig.fetch.feed) {
      setFormElement(elementConfig.fetch.feed);
    }
    // check if the dropdown placeholder is selected, if so pass it without JSON.parse
    if (config.useFormContext) {
      const { handleChange } = formContext;
      handleChange(value, elementConfig);
    }
    validate(value.value);
  };

  const openDropdown = (event: any) => {
    alignDropdown(event);
    setIsListOpen(!isListOpen);
  };

  const onChangeValue = (event: any) => {
    let selectedValue =
      event.currentTarget.dataset.type === "number"
        ? parseInt(event.currentTarget.dataset.value)
        : event.currentTarget.dataset.value;
    if (value === selectedValue) {
      setValue(null);
      setLabel(null);
      if (elementConfig.fetch && elementConfig.fetch.feed) {
        setFormElement(elementConfig.fetch.feed);
      }
      if (config.useFormContext) {
        const { handleChange } = formContext;
        handleChange(
          {
            value: null,
            label: null,
          },
          elementConfig
        );
      }
      validate(value);
      setIsListOpen(false);
      if (onChange) {
        onChange({
          value: null,
          label: null,
        });
      }
    } else {
      setValue(selectedValue);
      setLabel(event.currentTarget.dataset.label);
      if (elementConfig.fetch && elementConfig.fetch.feed) {
        setFormElement(elementConfig.fetch.feed);
      }
      if (config.useFormContext) {
        const { handleChange } = formContext;
        handleChange(
          {
            value: selectedValue,
            label: event.currentTarget.dataset.label,
          },
          elementConfig
        );
      }
      validate(value);
      setIsListOpen(false);
      if (onChange) {
        onChange({
          value: selectedValue,
          label: event.currentTarget.dataset.label,
        });
      }
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the target pop is open and the clicked target is not within the pop up,
      // then close the target pop
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsListOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isListOpen]);
  return (
    <Grid
      container
      className={`dropdown-container ${
        elementConfig.parentClass ? elementConfig.parentClass : ""
      }`}
      ref={refDropdown}
    >
      {elementConfig.displayLabel && elementConfig.label ? (
        <Box mb={1}>
          <label>{t(`${elementConfig.label}`)}{elementConfig.mandatoryPosition ? ":" : ""}</label>
          {config.mandatory ? (
            <label className={`mandatory ${
              elementConfig.mandatoryPosition ? "mandatory-lbl-position" : ""
            }`}>({t(`${config.mandatory}`)})</label>
          ) : null}
        </Box>
      ) : (
        <Box>
          {config.mandatory ? (
            <label className="mandatory">({t(`${config.mandatory}`)})</label>
          ) : null}
        </Box>
      )}
      <Box className="dropdown-singleselect">
        <div
          className={`dropdown-input-field ${
            config.disabled ? "disable-parent-box" : ""
          }  ${isListOpen ? "active" : ""} ${
            config.className ? config.className : ""
          }`}
        >
          <div className="dropdown-button" onClick={openDropdown}>
            <label>
              {label
                ? label
                : elementConfig.placeholder
                ? t(`${elementConfig.placeholder}`)
                : "select"}
            </label>
            {!config.disabled &&
              (isListOpen ? (
                <img
                  src={dropdown_arrow_blue}
                  alt="Gray icon"
                  className="arrow-up"
                />
              ) : (
                <img src={dropdown_arrow_black} alt="Gray icon" />
              ))}
          </div>
        </div>
        {isListOpen && (
          <div className={`dropdown-options custom-scroll ${dropDownPosition}`}>
            {/* {elementConfig.placeholder && (
              <div
                className="dropdown-option-item"
                data-value=""
                onClick={onChangeValue}
              >
                <label>{t(`${elementConfig.placeholder}`)}</label>
              </div>
            )} */}
            {options && options.length > 0 ? (
              options.map((optionItem: OptionItem, index: number) => {
                return (
                  <div
                    className="dropdown-option-item"
                    data-value={optionItem.value}
                    data-label={optionItem.label}
                    onClick={onChangeValue}
                    data-type={typeof optionItem.value}
                  >
                    <label>{optionItem.label}</label>
                    {value === optionItem.value && (
                      <div className="tick-icon" />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="dropdown-option-item">
                <label>No options</label>
              </div>
            )}
          </div>
        )}

        {validationObj.error && (
          <span className="form-field-error-msg">{validationObj.message}</span>
        )}
      </Box>
    </Grid>
  );
}
