import { Box, Grid } from "@mui/material";

import React, { FC } from "react";

type DropDownSelectProps = {
  values: Array<string>;
  isExpandFilter: boolean;
  handleClick: (
    value: string,
    index?: number,
    parentId?: number,
    indexOfValue?: number
  ) => void;
  index: number;
  style?: React.CSSProperties | undefined;
};
const DropDownSelect: FC<DropDownSelectProps> = ({
  values,
  isExpandFilter,
  handleClick,
  index,
  style,
}) => {
  return (
    <>
      {isExpandFilter && (
        <Grid
          container
          className={`dropdownbox-container`}
          style={{ cursor: "pointer" }}
        >
          <Box className="dropdownbox-multiselect">
            <div
              id="multidropdown-options"
              className="dropdown-options custom-scroll"
              style={style}
            >
              {values.map((value, i) => (
                <div
                  className="dropdownmulti-option-item"
                  data-value={value}
                  data-label={value}
                  key={`filter-${value}-${i}`}
                  onClick={() => handleClick(value, index, i)}
                >
                  <label style={{ cursor: "pointer" }}>{value}</label>
                </div>
              ))}
            </div>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default DropDownSelect;
