import { httpRequest } from "core/httpRequest";

/**
 * Get data required for a dropdown based on the url
 * @param url
 * @param requestType
 * @param requestBody
 * @returns
 */
export const getFormData = (
  url: any,
  requestType: string = "get",
  requestBody?: any,
  dataSource: any = "cdp"
) => {
  return httpRequest(url, requestType, requestBody,dataSource);
};
