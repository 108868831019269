import { ImageIcon } from "../ImageIcon";
import "./Button.scss";

/*Button Component
 *The props of Button component explained
 *type- Refers to whether a regular or small button is required. Possible values: regular, small, auto( width based on text)
 *variant- Refers to the button variation. Possible values: primary, secondary, tertiary
 *isDisabled- Refers to whether a button is disabled or not. Possible values: disabled, notDisabled
 *showArrow- Refers to whether arrow icon is needed in the button. Possible values: true, false
 *showLeftIcon- Refers to whether an icon is to be shown in the left side. Possible values: true, false
 *showRightIcon- Refers to whether an icon is to be shown in the right side. Possible values: true, false
 *iconclass- Refers to the icon needed to be shown.
 */

export function Button(props: any) {
  let properties = { ...props, propsclass: props.class ? props.class : "" };
  delete properties.class;
  const {
    title,
    onClick,
    type,
    variant,
    isDisabled,
    showArrow,
    showLeftIcon,
    showRightIcon,
    propsclass,
    iconclass,
    children,
    ...rest
  } = properties;

  return (
    <div className="custom-button-wrapper">
      <button
        title={title ? title : null}
        onClick={onClick ? onClick : null}
        className={`btn ${type ? type : "auto"} ${
          isDisabled ? isDisabled : "notDisabled"
        } ${variant ? variant : "primary"} ${propsclass ? propsclass : ""}`}
        {...rest}
      >
        {!!showLeftIcon && iconclass && (
          <div className="img-left-wrapper">
            <ImageIcon classes={iconclass}></ImageIcon>
          </div>
        )}
        <span>{children}</span>
        {!!showArrow && (
          <div
            className={`icon ${
              isDisabled === "disabled"
                ? "icon-right-arrow-gray"
                : variant === "primary"
                ? "icon-right-arrow-white"
                : "icon-right-arrow-blue"
            }`}
          />
        )}
        {!!showRightIcon && iconclass && (
          <div className="img-right-wrapper">
            <ImageIcon classes={iconclass}></ImageIcon>
          </div>
        )}
      </button>
    </div>
  );
}
