import { routes, DATE_FORMAT_CLIENT } from "core/constants";
import { format } from "date-fns";
import { OptionItem } from "models/dropdown";

/**
 * Parse a JSON Web Token
 * @param token
 * @returns
 */
const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const dateFormatConvertor = (
  dateValue: any = new Date(),
  dateFormat: string = DATE_FORMAT_CLIENT
) => {
  return format(dateValue, dateFormat);
};

const convertNotificationTime = (dateValue: any) => {
  let formatddate = dateValue.replace(" ", "T") + "Z";
  let dt = new Date(formatddate).toLocaleString();
  return dateFormatConvertor(new Date(dt), "dd-MM-yyyy HH:mm");
};

const getUploadRedirection = (source: any, history: any) => {
  switch (source) {
    case "targetHome":
      {
        /* let path: any = routes.newTargeting; */
        let path: any = routes.targetFilter;
        path = path.replace(":openTab", "apply_filters");
        history.push(path);
      }
      break;
    case "uploadTargets":
      {
        /* let path: any = routes.newTargeting; */
        let path: any = routes.targetFilter;
        path = path.replace(":openTab", "apply_filters");
        history.push(path);
      }
      break;
    case "simulatorHome":
      history.push(routes.simulator);
      break;
    default: {
      /* let path: any = routes.newTargeting; */
      let path: any = routes.targetFilter;
      path = path.replace(":openTab", "apply_filters");
      history.push(path);
    }
  }
};
function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export {
  parseJwt,
  dateFormatConvertor,
  getUploadRedirection,
  formatBytes,
  convertNotificationTime,
};

/**
 * Format array of strings into dropdown option item
 * @param itemArray
 * @returns
 */
export const formatStringArrayToOptions = (itemArray: any) => {
  let returnData: OptionItem[] = [];
  if (itemArray && Array.isArray(itemArray)) {
    returnData = itemArray.map((item: any) => {
      return {
        value: { 
          value: String(item) ? String(item) : "",
          label: String(item) ? String(item) : "",
        },
        label: String(item) ? String(item).charAt(0).toUpperCase() + String(item).slice(1) : "",
      };
    });
  }
  return returnData;
};

export const formatStringArrayToOptionsAccountLanguage = (itemArray: any) => {
  let returnData: OptionItem[] = [];
  if (itemArray && Array.isArray(itemArray)) {
    returnData = itemArray.map((item: any) => {
      return {
        value: { 
          value: String(item) ? String(item) : "",
          label: item === "en_US" ? "en" : String(item) ? String(item) : "",
        },
        label: item === "en_US" ? "en" :  String(item) ? String(item).charAt(0).toUpperCase() + String(item).slice(1) : "",
      };
    });
  }
  return returnData;
};
/**
 * Format array of strings into dropdown-group option item
 * @param itemArray
 * @returns
 */
export const formatStringArrayToGroupOptions = (itemArray: any) => {
  let returnData: OptionItem[] = [];
  if (itemArray && Array.isArray(itemArray)) {
    returnData = itemArray.map((item: any) => {
      return {
        value: String(item) ? String(item) : "",
        label: String(item) ? String(item).charAt(0).toUpperCase() + String(item).slice(1) : "",
        // value: item ? item : "",
        // label: item ? item : "",
      };
    });
  }
  return returnData;
};
export const formatGroupedDrodownValues = (data: any, field: any) => { 
  let returnValue: any = [];
  if (field === "prescription_goals") {
    if (
      data.prescription_goal_1 &&
      Array.isArray(data.prescription_goal_1) &&
      data.prescription_goal_1.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(
        data.prescription_goal_1
      );
      let groupItem = {
        group: "PRESCRIPTION GOAL 1",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
    if (
      data.prescription_goal_2 &&
      Array.isArray(data.prescription_goal_2) &&
      data.prescription_goal_2.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(
        data.prescription_goal_2
      );
      let groupItem = {
        group: "PRESCRIPTION GOAL 2",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
    if (
      data.prescription_goal_3 &&
      Array.isArray(data.prescription_goal_3) &&
      data.prescription_goal_3.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(
        data.prescription_goal_3
      );
      let groupItem = {
        group: "PRESCRIPTION GOAL 3",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
  } else if (field === "tendency") {
    if (
      data.tendency_1 &&
      Array.isArray(data.tendency_1) &&
      data.tendency_1.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.tendency_1);
      let groupItem = {
        group: "TENDENCY 1",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
    if (
      data.tendency_2 &&
      Array.isArray(data.tendency_2) &&
      data.tendency_2.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.tendency_2);
      let groupItem = {
        group: "TENDENCY 2",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
    if (
      data.tendency_3 &&
      Array.isArray(data.tendency_3) &&
      data.tendency_3.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.tendency_3);
      let groupItem = {
        group: "TENDENCY 3",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
  } else if (field === "payer") {
    if (
      data.payer_type &&
      Array.isArray(data.payer_type) &&
      data.payer_type.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.payer_type);
      let groupItem = {
        group: "PAYER TYPE",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
    if (
      data.payer_subtype &&
      Array.isArray(data.payer_subtype) &&
      data.payer_subtype.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.payer_subtype);
      let groupItem = {
        group: "PAYER SUBTYPE",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
    if (
      data.payer_archetype &&
      Array.isArray(data.payer_archetype) &&
      data.payer_archetype.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.payer_archetype);
      let groupItem = {
        group: "PAYER ARCHETYPE",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
  } else if (field === "person_type") {
    if (
      data.person_type &&
      data.person_type.Person &&
      Array.isArray(data.person_type.Person) &&
      data.person_type.Person.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.person_type.Person);
      let groupItem = {
        group: "PERSON",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
    if (
      data.person_type &&
      data.person_type.Institution &&
      Array.isArray(data.person_type.Institution) &&
      data.person_type.Institution.length > 0
    ) {
      let groupValue = formatStringArrayToGroupOptions(data.person_type.Institution);
      let groupItem = {
        group: "INSTITUTION",
        value: groupValue,
      };
      returnValue.push(groupItem);
    }
   
  } 
  return returnValue;
};
export const formatOptions = (itemArray: any) => {
  let returnData: OptionItem[] = [];
  if (itemArray && Array.isArray(itemArray)) {
    returnData = itemArray.map((item: any) => {
      return {
        value: item,
        label: item.label ? item.label : "",
      };
    });
  }
  return returnData;
};
export const formatMultiDropdownBoxOptions = (itemArray: any) => {
  let returnData: OptionItem[] = [];
  if (itemArray && Array.isArray(itemArray)) {
    returnData = itemArray.map((item: any) => {
      return {
        value: item,
        label: item,
      };
    });
  }
  return returnData;
};
export const formatBooleanArrayToOptions = (itemArray: any) => {
  let returnData: OptionItem[] = [];
  if (itemArray && Array.isArray(itemArray)) {
    returnData = itemArray.map((item: boolean) => {
      return {
        value: {
          value: item ? item : false,
          label: item ? "yes" : "no",
        },
        label: item ? "yes" : "no",
      };
    });
  }
  return returnData;
};
/** A mapping of default values for each form element. Useful at the time of resetting the value */
const defaultValuesForFormElements: any = {
  dropdown: null,
  "dropdown-group": [],
  text: "",
  autosuggest: {},
  "autosuggest-multiple": [],
  checkbox: [],
  radio: null,
  "daterange-picker": null,
  "date-picker": null,
  "group-multiselect": [],
  checckBox_Normal: false,
};

/**
 * Returns the default value for a form element based on it's type
 * @param type
 * @returns
 */
export const resetFormField = (type: string) => {
  return defaultValuesForFormElements[type]
    ? defaultValuesForFormElements[type]
    : "";
};

export const getValues = (values: any) => {
  return values && values.length > 0
    ? values.map((item: any) => {
        return item.value;
      })
    : null;
};
export const dropDownValueFormatter = (value: any) => {
  return { 
    // value: value, label: value 
    value: String(value) ? String(value) : "",
    label: String(value) ? String(value).charAt(0).toUpperCase() + String(value).slice(1) : "",
  };
};
