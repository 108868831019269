import { Box } from "@mui/material";
import { Button } from "components/shared/Button";
import { routes } from "core/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const TargetV2Header = () => {
  const { t } = useTranslation();
  let history: any = useHistory();


  const goToDashboard = () =>{    
    let path = routes.dashboard;
    history.push(path);
  }
  
  return (
    <Box className="target-header">
      <Box paddingLeft="14px">
        <h3 className="target-title">{t("create_target_list")}</h3>
      </Box>
      <Box paddingRight="24px">
        <Button type="small" variant="secondary" onClick={goToDashboard}>
          {t("view_saved_result")}
        </Button>
      </Box>
    </Box>
  );
};

export default TargetV2Header;
