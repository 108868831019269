import { Box } from "@mui/material";
import { SubGroupFilterItem } from "pages/TargetV2/types/targetV2.types";
import React, { FC, useEffect, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import DropDownSelect from "../dropdownSelect/DropDownSelect";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";

type SelectOperatorBetweenGroupsProps = {
  subGroupFilterItemChildren: SubGroupFilterItem;
  subGroupFilterItem: SubGroupFilterItem;
  index: number;
  handleUpdateOperatorBetweenGroupsClick: (
    operator: string,
    indexOfGroups?: number
  ) => void;
  isExpandedFilterOfOperatorBetweenGroups: boolean;
  setIsExpandedFilterOfOperatorBetweenGroups: (isExpand: boolean) => void;
};
const SelectOperatorBetweenGroups: FC<SelectOperatorBetweenGroupsProps> = ({
  index,
  subGroupFilterItemChildren,
  handleUpdateOperatorBetweenGroupsClick,
  isExpandedFilterOfOperatorBetweenGroups,
  setIsExpandedFilterOfOperatorBetweenGroups,
  subGroupFilterItem,
}) => {
  const refDropdownBetweenGroups = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { arrayOfOperators } = useTargetV2Context();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        refDropdownBetweenGroups.current &&
        !refDropdownBetweenGroups.current.contains(e.target)
      ) {
        setIsExpandedFilterOfOperatorBetweenGroups(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandedFilterOfOperatorBetweenGroups, subGroupFilterItemChildren]);

  return (
    <Box
      className="operator-between-groups-container"
      onClick={() =>
        setIsExpandedFilterOfOperatorBetweenGroups(
          !isExpandedFilterOfOperatorBetweenGroups
        )
      }
    >
      <Box
        className="operator-between-groups-wrapper"
        ref={refDropdownBetweenGroups}
      >
        <Box display="flex" justifyContent="space-between">
          <Box className="operator-between-groups-title">
            {subGroupFilterItem.operatorsBetweenGroups?.[index] ||
              t("select_your_operator")}
          </Box>
          <ArrowDropDownIcon
            style={{
              transform: isExpandedFilterOfOperatorBetweenGroups
                ? "rotate(180deg)"
                : "",
              cursor: "pointer",
              color: "#149ccc",
            }}
          />
        </Box>
        <DropDownSelect
          values={arrayOfOperators}
          isExpandFilter={isExpandedFilterOfOperatorBetweenGroups}
          handleClick={handleUpdateOperatorBetweenGroupsClick}
          index={index}
        />
      </Box>
    </Box>
  );
};

export default SelectOperatorBetweenGroups;
