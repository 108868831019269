import {
  GroupType,
  SubGroupFilterItem,
} from "pages/TargetV2/types/targetV2.types";
import React, { FC } from "react";
import PastInteractionSubGroupContainer from "./pastInteraction/PastInteractionSubGroupContainer";
//import MCCPContainer from "./mccp/MCCPContainer";
import SurveyContainer from "./survey/SurveyContainer";
import { Box } from "@mui/material";

type SubGroupFilterCustomContainerProps = {
  subGroupFilterItem: SubGroupFilterItem;
  level: number;
  index: number;
  groupType: GroupType;
};

const SubGroupFilterCustomContainer: FC<SubGroupFilterCustomContainerProps> = ({
  subGroupFilterItem,
  level,
  index,
  groupType,
}) => {
  return (
    <Box
      marginRight="22px"
      marginTop="34px"
      marginLeft="12px"
      marginBottom="22px"
    >
      {groupType === GroupType.PAST_INTERACTION ? (
        <PastInteractionSubGroupContainer
          subGroupFilterItem={subGroupFilterItem}
          index={index}
        />
      ) 
      // : groupType === GroupType.MCCP ? (
      //   <MCCPContainer subGroupFilterItem={subGroupFilterItem} index={index} />
      // )
       : groupType === GroupType.SURVEY ? (
        <SurveyContainer
          subGroupFilterItem={subGroupFilterItem}
          index={index}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SubGroupFilterCustomContainer;
