import { OptionItem, ParamseterItem } from "models/dropdown";
import { getFormData } from "services/formService";

const getData = (
  elementConfig: any,
  formvalues: any | undefined
): Promise<OptionItem[]> => {
  return new Promise((resolve) => {
    if (elementConfig.options && Array.isArray(elementConfig.options)) {
      // dropdown options are provided with config itself
      resolve(elementConfig.options);
    } else if (elementConfig.fetch && elementConfig.fetch.url) {
      const requestType = elementConfig.fetch.type
        ? elementConfig.fetch.type
        : "get";
      let fetchDataUrl = elementConfig.fetch.url;
      let requestBody: any = {};
      let dataSource: any=elementConfig.fetch.dataSource
      const urlParameters = elementConfig.fetch.params
        ? elementConfig.fetch.params
        : [];
        
      // dropdown options needs to be loaded via API
      // append parameters to the API url. Eg. state may be loaded based on country.
      // So URL will be /api/state?country=4 in case of get request
      // for post requests, the data will be passed as request body
      if (requestType === "get") {
        let urlParamString = urlParameters.length ? "?" : "";
        urlParameters.forEach((param: ParamseterItem, index: number) => {
          let paramvalue = param.value
            ? param.value
            : param.valueReference // valueReference indicates one of the fields in the same form state
            ? formvalues[param.valueReference]
            : "";
          paramvalue =
            typeof paramvalue === "object" ? paramvalue.value : paramvalue;
          urlParamString += param.key + "=" + paramvalue;
          urlParamString += index < urlParameters.length - 1 ? "&" : "";
        });
        // append parameter string to API URL to form the actual URL
        fetchDataUrl = {
          ...elementConfig.fetch.url,
          ...{ url: elementConfig.fetch.url.url + urlParamString },
        };
      } else {
        urlParameters.forEach((param: ParamseterItem, index: number) => {
          let paramvalue = param.value
            ? param.value
            : param.valueReference // valueReference indicates one of the fields in the same form state
            ? formvalues[param.valueReference]
            : "";
          paramvalue =
            typeof paramvalue === "object" ? paramvalue.value : paramvalue;
          requestBody[param.key] = paramvalue;
        });
      }
      getFormData(fetchDataUrl, requestType, requestBody,dataSource)?.then((data: any) => {
        const formattedData =
          data && data.data ? elementConfig.fetch?.formatter(data.data) : [];
        resolve(formattedData);
      });
    }
  });
};

export { getData };
