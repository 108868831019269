import React from "react";
import { useRecoilValue } from "recoil";
import { Toast } from "components/shared/Toast";
import { customToastInfoState, notificationState } from "recoil/atoms";
import "./Notification.scss";
import { CustomToast } from "components/shared/CustomToast";

export const Notification = (props: any) => {
  const notificationConfig = useRecoilValue(notificationState);
  const customToastInfoStateConfig = useRecoilValue(customToastInfoState);

  return (
    <>
    {props.customToast ? <CustomToast toastList={customToastInfoStateConfig}
         position="top-right-new"/>  :  <Toast {...notificationConfig} />}     
    </>
  );
};
